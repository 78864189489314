<template>
  <div class="dynamic">
    <div id="dynamic-section" :class="{ 'dynamic-fix-nav': navBarFixed }">
      <div class="dynamic-section">
        <li
          :class="{ 'dynamic-section-select': navBarSelected == 0 }"
          @click="navBarSelect(0)"
        >
          精品案例
        </li>
        <li
          :class="{ 'dynamic-section-select': navBarSelected == 1 }"
          @click="navBarSelect(1)"
        >
          企业新闻
        </li>
        <li
          :class="{ 'dynamic-section-select': navBarSelected == 2 }"
          @click="navBarSelect(2)"
        >
          视界热点
        </li>
        <li
          :class="{ 'dynamic-section-select': navBarSelected == 3 }"
          @click="navBarSelect(3)"
        >
          聚焦新国货
        </li>
      </div>
    </div>
    <!-- 最新成绩 -->
    <div class="dynamic-main">
      <div id="latest" style="height: 80px"></div>
      <div class="dynamic-content">
        <div class="dynamic-top">
          <div class="dynamic-top-title">
            <!-- <img src="../../assets/title_jqrd.png" /> -->
            <div>企业新闻</div>
            <div class="dynamic-top-title-bar"></div>
          </div>
          <div class="dynamic-top-right">
            <a @click="tojump(3)">查看全部</a>
            <img src="../../assets/icon_right.png" />
          </div>
        </div>
        <div class="dynamic-latest">
          <div
            class="dynamic-latest-item"
            v-for="(item, index) in newsResults"
            :key="index"
            @click="gotoDetails(item.id)"
            v-show="index < 4"
          >
            <img class="dynamic-latest-item-img" v-lazy="item.absolutePath" />
            <div class="dynamic-latest-item-text">
              <span class="dynamic-latest-item-title">{{
                item.newsTopic
              }}</span>
              <span class="dynamic-latest-item-date">{{
                item.newsDate | formatTime
              }}</span>
            </div>
          </div>
          <div v-if="newsResults.length == 1" class="dynamic-latest-item"></div>
          <div v-if="newsResults.length == 1" class="dynamic-latest-item"></div>
          <div v-if="newsResults.length == 1" class="dynamic-latest-item"></div>
          <div v-if="newsResults.length == 2" class="dynamic-latest-item"></div>
          <div v-if="newsResults.length == 2" class="dynamic-latest-item"></div>
          <div v-if="newsResults.length == 3" class="dynamic-latest-item"></div>
        </div>
      </div>
    </div>
    <!-- 企业新闻 -->
    <div class="dynamic-main2">
      <div id="news" style="height: 80px"></div>
      <div class="dynamic-content">
        <div class="dynamic-top">
          <div class="dynamic-top-title">
            <!-- <img src="../../assets/title_jqrd.png" /> -->
            <div>视界热点</div>
            <div class="dynamic-top-title-bar"></div>
          </div>
          <div class="dynamic-top-right">
            <a @click="tojump(4)">查看全部</a>
            <img src="../../assets/icon_right.png" />
          </div>
        </div>
        <div class="dynamic-latest">
          <div
            class="dynamic-latest-item"
            v-for="(item, index) in internetList"
            :key="index"
            @click="gotoDetails(item.id)"
            v-show="index < 4"
          >
            <img class="dynamic-latest-item-img" v-lazy="item.absolutePath" />
            <div class="dynamic-latest-item-text">
              <span class="dynamic-latest-item-title">{{
                item.newsTopic
              }}</span>
              <span class="dynamic-latest-item-date">{{
                item.newsDate | formatTime
              }}</span>
            </div>
          </div>
          <div
            v-if="internetList.length == 1"
            class="dynamic-latest-item"
          ></div>
          <div
            v-if="internetList.length == 1"
            class="dynamic-latest-item"
          ></div>
          <div
            v-if="internetList.length == 1"
            class="dynamic-latest-item"
          ></div>
          <div
            v-if="internetList.length == 2"
            class="dynamic-latest-item"
          ></div>
          <div
            v-if="internetList.length == 2"
            class="dynamic-latest-item"
          ></div>
          <div
            v-if="internetList.length == 3"
            class="dynamic-latest-item"
          ></div>
        </div>
      </div>
    </div>
    <div class="dynamic-main2">
      <div id="focus" style="height: 80px"></div>
      <div class="dynamic-content">
        <div class="dynamic-top">
          <div class="dynamic-top-title">
            <!-- <img src="../../assets/title_jqrd.png" /> -->
            <div>聚焦新国货</div>
            <div class="dynamic-top-title-bars"></div>
          </div>
          <div class="dynamic-top-right">
            <a @click="tojump(5)">查看全部</a>
            <img src="../../assets/icon_right.png" />
          </div>
        </div>
        <div class="dynamic-latest">
          <div
            class="dynamic-latest-item"
            v-for="(item, index) in jsNewList"
            :key="index"
            @click="gotoDetails(item.id)"
            v-show="index < 4"
          >
            <img class="dynamic-latest-item-img" v-lazy="item.absolutePath" />
            <div class="dynamic-latest-item-text">
              <span class="dynamic-latest-item-title">{{
                item.newsTopic
              }}</span>
              <span class="dynamic-latest-item-date">{{
                item.newsDate | formatTime
              }}</span>
            </div>
          </div>
          <div v-if="jsNewList.length == 1" class="dynamic-latest-item"></div>
          <div v-if="jsNewList.length == 1" class="dynamic-latest-item"></div>
          <div v-if="jsNewList.length == 1" class="dynamic-latest-item"></div>
          <div v-if="jsNewList.length == 2" class="dynamic-latest-item"></div>
          <div v-if="jsNewList.length == 2" class="dynamic-latest-item"></div>
          <div v-if="jsNewList.length == 3" class="dynamic-latest-item"></div>
        </div>
      </div>
    </div>
    <!-- 媒体资料库 -->
    <div class="dynamic-main3">
      <div class="dynamic-content2">
        <div class="dynamic-top">
          <div class="dynamic-top-title">
            <div>媒体资料库</div>
            <!-- <div class="dynamic-top-title-bars"></div> -->
          </div>
          <div class="dynamic-top-right">
            <router-link to="/MediaVideo" v-if="mediaSelected == 1"
              >查看全部</router-link
            >
            <router-link to="/Activity" v-if="mediaSelected == 2"
              >查看全部</router-link
            >
            <router-link to="/BusinessImg" v-if="mediaSelected == 3"
              >查看全部</router-link
            >
            <img src="../../assets/icon_right.png" />
          </div>
        </div>
        <div class="dynamic-media">
          <div
            :class="
              mediaSelected == 1
                ? 'dynamic-media-select'
                : 'dynamic-media-unselect'
            "
            @click="chooseMediaBar(1)"
          >
            <img src="../../assets/icon_spzl.png" />
            <span>视频资料</span>
          </div>
          <div
            :class="
              mediaSelected == 2
                ? 'dynamic-media-select'
                : 'dynamic-media-unselect'
            "
            @click="chooseMediaBar(2)"
          >
            <img src="../../assets/icon_hdtj.png" />
            <span>活动图集</span>
          </div>
          <div
            :class="
              mediaSelected == 3
                ? 'dynamic-media-select'
                : 'dynamic-media-unselect'
            "
            @click="chooseMediaBar(3)"
          >
            <img src="../../assets/icon_qyxx.png" />
            <span>企业形象</span>
          </div>
        </div>
      </div>
    </div>
    <div class="dynamic-main" style="margin-bottom: 100px">
      <div class="dynamic-content2">
        <!-- video -->
        <div class="dynamic-media-content" v-show="mediaSelected == 1">
          <div
            v-for="(item, index) in videoList"
            :key="index"
            @click="showVideo(index)"
            style="position: relative"
          >
            <img class="mediaImg" v-lazy="item.filePath" />
            <img class="videoplay" src="../../assets/play-icon.png" />
            <div class="mediaTitle">{{ item.newsTopic }}</div>
          </div>
        </div>
        <!-- img -->
        <div class="dynamic-media-content" v-show="mediaSelected == 2">
          <div v-for="(item, index) in activityList" :key="index">
            <img
              class="mediaImg"
              v-lazy="item.list[0].filePath"
              @click="showImg(index)"
            />
            <div class="mediaTitle">{{ item.newsTopic }}</div>
          </div>
        </div>
        <!-- business -->
        <div class="dynamic-media-content" v-show="mediaSelected == 3">
          <div v-for="(item, index) in businessPhotoList" :key="index">
            <img
              class="mediaImg"
              v-lazy="item.filePath"
              @click="showBusiness(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 视频预览 -->
    <div v-if="showVideoFlag">
      <div class="video-pop" style="display: block">
        <div class="pop-bg" @click.stop="closeModule()"></div>
        <div class="pop-box">
          <img
            class="close"
            src="../../assets/close.png"
            @click.stop="closeModule()"
          />
          <video
            width="100%"
            loop=""
            preload="auto"
            controls=""
            autoplay=""
            :src="videoList[showVideoIndex].videoPath"
            class="video"
          ></video>
        </div>
      </div>
    </div>
    <!-- 企业形象弹窗 -->
    <div v-if="showBusinessFlag">
      <div class="image-pop" style="display: block">
        <div class="pop-bg" @click.stop="closeModule()"></div>
        <div class="pop-box">
          <img
            class="close"
            src="../../assets/close.png"
            @click.stop="closeModule()"
          />
          <div class="pic-box">
            <div
              class="pic-list active-pic"
              :style="{
                backgroundImage:
                  'url(' + businessPhotoList[showBusinessIndex].filePath + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }"
            ></div>
          </div>
        </div>
        <div
          class="pre-btn btn"
          v-show="showBusinessIndex > 0"
          @click.stop="changeBusiness('pre')"
        >
          <img src="../../assets/sli-left-icon.png" />
        </div>
        <div
          class="next-btn btn"
          v-show="showBusinessIndex < businessPhotoList.length - 1"
          @click.stop="changeBusiness('next')"
        >
          <img src="../../assets/sli-right-icon.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ipHeader,
  num,
  getNewsResults,
  getInternetList,
  getJsNewList,
  getVideoList,
  getBusinessPhotoList,
  getActivityList,
  visited,
} from "../../api/index";

export default {
  name: "dynamic",

  data() {
    return {
      topicCode: 1004,
      startScroll: false, //点击标签时 不判断滚动锚点
      navBarFixed: false,
      showVideoFlag: false, //显示视频预览模块
      showImgFlag: false, //显示图册预览模块
      showBusinessFlag: false, //显示企业形象预览模块
      activeTab: "spzl",
      navBarSelected: 1,
      newsResults: [], //最新成绩/近期热点
      recruitList: [], //最新招聘
      internetList: [], //最新资讯
      jsNewList: [], //新国货动态
      worldList: [], //视界动态
      videoList: [], //视频资料
      businessPhotoList: [], //企业形象
      activityList: [], //活动图集
      mediaSelected: sessionStorage.mediaSelected || 1, //媒体资料库切换
      showVideoIndex: 0, //选择的视频资料
      showImgIndex: 0, //选择的活动图集
      showBusinessIndex: 0, //选择的企业形象
      selector: "",
      params: { pageNum: 1, pageSize: 3, status: num },
    };
  },
  computed: {
    dynamic() {
      return "tab-" + this.activeTab;
    },
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },

  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
    // localStorage.removeItem("toId");
  },
  created() {
    this.visit();
    this.show();
    this.$nextTick(() => {
      if (localStorage.getItem("toId")) {
        this.getlocal();
      } else {
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
        });
      }
    });
  },
  filters: {
    /* 去除html标签 */
    clearHtmlCode(val) {
      val = val.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
      val = val.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
      val = val.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
      val = val.replace(/&nbsp;/gi, ""); //去掉&nbsp;
      return val;
    },
    /* 时间格式转换 */
    formatTime(val) {
      if (val) {
        return val.replace(/-/g, ".").substring(0, 10);
      } else {
        return "";
      }
    },
  },
  methods: {
    visit() {
      this.visiting();
    },
    visiting() {
      visited(this.topicCode)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    /* 获取跳转的锚点滚动 */
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId); //如果对应id存在，就跳转
      if (selectId && toElement) {
        toElement.scrollIntoView(true);
        localStorage.setItem("toId", "");
      }
    },
    /* 跳转锚点 */
    gotoSelectId() {
      this.$nextTick(() => {
        if (localStorage.getItem("toId")) {
          this.getlocal();
        }
      });
    },
    /* 调用接口 */
    show() {
      /* 最新成绩/近期热点 */
      getNewsResults()
        .then((res) => {
          console.log("最新成绩/近期热点", res);
          this.newsResults = res.data.data;
          this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
      /* 最新资讯 */
      getInternetList()
        .then((res) => {
          //console.log("最新资讯", res);
          this.internetList = res.data.data;
          this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
      /* 新国货动态 */
      getJsNewList()
        .then((res) => {
          // console.log("新国货动态", res.data.data);
          this.jsNewList = res.data.data;
          this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
      // /* 视界动态 */
      // getWorldList(this.params)
      //   .then((res) => {
      //     // console.log("视界动态", res.data.data.data);
      //     this.worldList = res.data.data.data;
      //     this.gotoSelectId();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      /* 视频资料 */
      getVideoList()
        .then((res) => {
          // console.log("视频资料", res);
          this.videoList =
            res.data.data.length > 6
              ? res.data.data.slice(0, 6)
              : res.data.data;
          this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
      /* 企业形象 */
      getBusinessPhotoList()
        .then((res) => {
          // console.log("企业形象", res.data.data);
          this.businessPhotoList =
            res.data.data.length > 6
              ? res.data.data.slice(0, 6)
              : res.data.data;
          this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
      /* 活动图集 */
      getActivityList()
        .then((res) => {
          // console.log("活动图集", res.data.data);
          this.activityList =
            res.data.data.length > 6
              ? res.data.data.slice(0, 6)
              : res.data.data;
          this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /* 图片地址转换 */
    carouselImg(item) {
      return `${ipHeader}nsc/file${item}`;
    },
    /* 跳转详情 */
    gotoDetails(id) {
      this.$router.push({ path: "/NewsDetails", query: { id: id } });
    },
    toguide(page, selectID) {
      localStorage.setItem("toId", selectID);
      this.$router.push({ path: page });
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop =
        document.getElementById("dynamic-section").offsetHeight +
        document.getElementsByClassName("nav-bg1")[0].offsetHeight -
        1;
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }

      /* 吸顶栏定位 */
      if (!this.startScroll) {
        if (
          document.querySelector("#latest").offsetTop - 80 <
          document.documentElement.scrollTop
        ) {
          this.navBarSelected = 1;
        }
        if (
          document.querySelector("#news").offsetTop - 80 <
          document.documentElement.scrollTop
        ) {
          this.navBarSelected = 2;
        }
        if (
          document.querySelector("#focus").offsetTop - 80 <
          document.documentElement.scrollTop
        ) {
          this.navBarSelected = 3;
        }
      }
    },
    /* 吸顶栏点击 */
    navBarSelect(index) {
      let that = this;
      if (index == 0) {
        localStorage.setItem("toId", "cases");
        this.$router.push({ path: "/Cases" });
        return;
      }
      this.navBarSelected = index;
      // var selector = index == 1 ? "#latest" : "#news";
      if (index == 1) {
        this.selector = "#latest";
      } else if (index == 2) {
        this.selector = "#news";
      } else if (index == 3) {
        this.selector = "#focus";
      }

      let total = this.$el.querySelector(this.selector).offsetTop;
      let distance = document.documentElement.scrollTop;

      this.startScroll = true;
      if (total > distance) {
        window.scrollTo({
          top: total,
          behavior: "smooth",
        });
        setTimeout(() => {
          that.startScroll = false;
        }, 600);
      } else {
        window.scrollTo({
          top: total,
          behavior: "smooth",
        });
        setTimeout(() => {
          that.startScroll = false;
        }, 600);
      }
    },
    chooseMediaBar(index) {
      this.mediaSelected = index;
      sessionStorage.setItem("mediaSelected", this.mediaSelected);
    },
    /* 视频预览 */
    showVideo(index) {
      this.showVideoFlag = true;
      this.showVideoIndex = index;
    },
    /* 图集预览 */
    showImg(index) {
      this.showImgFlag = true;
      this.showImgIndex = index;
      this.$router.push({ path: "/Activity" });
    },
    /* 企业形象预览 */
    showBusiness(index) {
      this.showBusinessFlag = true;
      this.showBusinessIndex = index;
    },
    /* 关闭弹窗 */
    closeModule() {
      this.showVideoFlag = false;
      this.showImgFlag = false;
      this.showBusinessFlag = false;
    },
    /* 切换图片 */
    changeBusiness(type) {
      switch (type) {
        case "pre":
          this.showBusinessIndex--;
          break;
        case "next":
          this.showBusinessIndex++;
          break;
        default:
          break;
      }
    },
    tojump(id) {
      this.$router.push({ path: "/hotspot", query: { id: id } });
    },
  },
};
</script>
<style scoped>
#dynamic {
  width: 100%;
  margin: 0;
  padding: 0;
}
#dynamic-section {
  width: 100%;
  border-bottom: 1px solid #b8b8b8;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 2;
}
.dynamic-section {
  display: flex;
  justify-content: flex-start;
  height: 54px;
  background-color: white;
  width: 1280px;
  margin: 0 auto;
}
#dynamic-section li {
  font: 16px/40px "";
  padding: 0px 3px;
  text-align: center;
  height: 52px;
  line-height: 52px;
  margin-left: 73px;
  font-size: 17px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}
.dynamic-section-select {
  border-bottom: 3px solid #0075ff;
  border-radius: 2px;
}
.dynamic-main {
  min-height: 420px;
}
.dynamic-main1 {
  width: 100%;
  background: #f3f3f3;
}
.dynamic-main2 {
  width: 100%;
  background: #f3f3f3;
  min-height: 380px;
  /* border-bottom: 1px solid #b8b8b8; */
}
.dynamic-main3 {
  width: 100%;
  background: #f3f3f3;
  /* border-bottom: 1px solid #b8b8b8; */
}
.dynamic-content {
  width: 1280px;
  margin: 0px auto;
  padding: 0px 0px 27px 0px;
  position: relative;
}
.dynamic-content2 {
  width: 1280px;
  margin: 0px auto;
  padding-top: 53px;
  position: relative;
}
.dynamic-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 73px;
}
.dynamic-top-title {
  font: 28px/33px "siyuan-Normal";
  color: #333333;
  display: flex;
  flex-direction: column;
}
.dynamic-top-title img {
  width: 111px;
}
.dynamic-top-title-bar {
  width: 113px;
  height: 4px;
  background: #0075ff;
  margin-left: 25px;
  margin-top: 10px;
  border-radius: 2px;
}
.dynamic-top-title-bars {
  width: 145px;
  height: 4px;
  background: #0075ff;
  margin-left: 25px;
  margin-top: 10px;
  border-radius: 2px;
}
.dynamic-top-right {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dynamic-top-right a {
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  font-family: siyuan-Regular;
}
.dynamic-top-right img {
  width: 13px;
  margin-left: 10px;
}

/*  */
.dynamic-latest {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 73px;
}
.dynamic-latest-item {
  display: flex;
  flex-direction: column;
  width: 253px;
  cursor: pointer;
}
.dynamic-latest-item-img {
  width: 253px;
  height: 142px;
  object-fit: cover;
  margin-top: 16px;
  border-radius: 5px;
}
.dynamic-latest-item-text {
  width: 236px;
  height: 107px;
  background: #ffffff;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.13);
  opacity: 0.9;
  border-radius: 2px;
  margin-top: -48px;
  margin-left: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(0%);
  -ms-transform: translateY(0%); /* IE 9 */
  -moz-transform: translateY(0%); /* Firefox */
  -webkit-transform: translateY(0%); /* Safari 和 Chrome */
  -o-transform: translateY(0%); /* Opera */
  border-radius: 11px;
  transition: all 0.8s;
  -moz-transition: all 0.8s; /* Firefox 4 */
  -webkit-transition: all 0.8s; /* Safari and Chrome */
  -o-transition: all 0.8s; /* Opera */
}
.dynamic-latest-item-text:hover {
  transform: translateY(-10px);
  -ms-transform: translateY(-10px); /* IE 9 */
  -moz-transform: translateY(-10px); /* Firefox */
  -webkit-transform: translateY(-10px); /* Safari 和 Chrome */
  -o-transform: translateY(-10px); /* Opera */
  transition: all 0.8s;
  -moz-transition: all 0.8s; /* Firefox 4 */
  -webkit-transition: all 0.8s; /* Safari and Chrome */
  -o-transition: all 0.8s; /* Opera */
}
.dynamic-latest-item-title {
  /* margin-top: 14px; */
  font-size: 16px;
  line-height: 20px;
  font-family: "siyuan-Medium";
  color: #333333;
  padding: 16px 16px 0px 16px;
  /* font-weight: bold; */
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.dynamic-latest-item-content {
  margin-top: 17px;
  font-size: 14px;
  line-height: 17px;
  font-family: "siyuan-Normal";
  color: #333333;
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.dynamic-latest-item-date {
  margin-top: 19px;
  font-size: 12px;
  color: #333333;
  font-family: "siyuan-Regular";
  align-self: flex-end;
  padding: 0px 16px 16px 16px;
}

/* 企业新闻 */
.dynamic-news {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 73px;
}
.dynamic-news-item {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 37px 0px 0px 0px;
  padding: 16px;
  /* background: white;
  border-radius: 8px; */
}
.dynamic-news-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 17px;
  color: #333333;
  font-weight: 400;
}
.dynamic-news-right {
  display: flex;
  align-items: center;
}
.dynamic-news-right a {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  font-family: siyuan-Regular;
}
.dynamic-news-right li {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  font-family: siyuan-Regular;
  cursor: pointer;
}
.dynamic-news-right img {
  margin-left: 8px;
  width: 13px;
}
.dynamic-news-item-list {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.dynamic-news-item-content {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin-top: 26px;
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.dynamic-news-item-date {
  margin-top: 18px;
  font-size: 12px;
  color: #333333;
}

/* 媒体资料库 */
.dynamic-media {
  margin-top: 28px;
  display: flex;
  align-items: center;
  padding: 0px 73px;
}
.dynamic-media-select,
.dynamic-media-unselect {
  height: 183px;
  width: 181px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.dynamic-media-select {
  height: 177px;
  background: white;
  border-top: 8px solid #0075ff;
  border-radius: 4px;
}
.dynamic-media-unselect img,
.dynamic-media-select img {
  width: 74px;
}
.dynamic-media-unselect img {
  margin-top: 6px;
}
.dynamic-media-unselect span,
.dynamic-media-select span {
  margin: 39px 0px 26px 0px;
  font-size: 16px;
  font-family: "siyuan-Regular";
  color: #333333;
}

.dynamic-media-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
  padding: 0px 73px;
}
.mediaImg {
  width: 333px;
  height: 200px;
  margin-top: 33px;
  cursor: pointer;
  object-fit: cover;
}
.videoplay {
  position: absolute;
  width: 54px;
  height: 54px;
  top: 50%;
  margin-top: -27px;
  left: 50%;
  margin-left: -27px;
}
.dynamic-media-content::after {
  content: "";
  width: 333px;
}
.mediaTitle {
  font-size: 14px;
  line-height: 17px;
  font-family: "siyuan-Normal";
  color: #333333;
  font-weight: 400;
  margin-top: 12px;
  text-align: center;
  width: 333px;
}

/* 视频模块 */
.video-pop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: none;
}
.video-pop .pop-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}
.video-pop .pop-box {
  width: 680px;
  height: 383px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background: #000;
  box-sizing: border-box;
}
.video-pop .pop-box .close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0px;
  top: -40px;
  cursor: pointer;
}
.video-pop .pop-box .video {
  width: 100%;
  height: 100%;
}

/* 图集模块 */
.image-pop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: none;
}
.image-pop .pop-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}
.image-pop .pop-box {
  width: 800px;
  height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background: #000;
  box-sizing: border-box;
}
.image-pop .pop-box .close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 9;
}
.image-pop .pop-box .pic-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.image-pop .pop-box .pic-box .pic-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.image-pop .pop-box .pic-box .pic-list.active-pic {
  display: block;
}
.image-pop .pop-box .pic-box .pic-list img {
  opacity: 0;
  width: 100%;
  height: 100%;
}
.image-pop .btn {
  width: 40px;
  height: 40px;
  background: #919797;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  cursor: pointer;
}
.image-pop .btn img {
  width: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.image-pop .pre-btn {
  left: 0;
}
.image-pop .next-btn {
  right: 0;
}

.dynamic-fix-nav {
  position: fixed;
  top: 0;
  z-index: 99;
}
</style>
