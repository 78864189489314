<template>
  <div style="width: 100%">
    <div id="detail">
      <div class="title">{{ title }}</div>
      <div class="date">{{ newsDate }}</div>
      <div class="content ql-editor" v-html="content"></div>
    </div>
  </div>
</template>
<script>
import { getNewsById } from "../../api/index";
export default {
  name: "detail",
  data() {
    return {
      id: "",
      title: "",
      content: "",
      newsDate: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.show();
  },
  mounted() {},
  methods: {
    /* 调用接口 */
    show() {
      getNewsById(this.id)
        .then((res) => {
          // console.log("新闻详情", res.data.data);
          this.title = res.data.data.newsTopic;
          this.newsDate = res.data.data.newsDate
            ? res.data.data.newsDate.replace(/-/g, ".").substring(0, 10)
            : "";
          this.content = res.data.data.newsContent;
          this.scrolltoTop();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    scrolltoTop() {
      this.$nextTick(() => {
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
        });
      });
    },
  },
};
</script>
<style scoped>
.content >>> img {
  max-width: 800px;
}
.content {
  text-align: justify;
}
#detail {
  width: 830px;
  padding: 72px;
  margin: 0 auto;
}
.title {
  font-size: 24px;
  /* line-height: 44px; */
  font-weight: 500;
  color: #333333;
  margin-bottom: 8px;
}
.date {
  font-size: 14px;
  color: #333333;
  padding-bottom: 6px;
  margin-bottom: 8px;
  border-bottom: 1px solid #b8b8b8;
}
img {
  max-width: 800px !important;
}
</style>
