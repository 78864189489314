<template>
  <div id="dynamic">
    <div id="dynamic-section" :class="{ 'dynamic-fix-nav': navBarFixed }">
      <div class="dynamic-section">
        <li class="dynamic-section-select">{{ this.topic }}</li>
      </div>
    </div>
    <!-- 近期热点 -->
    <div class="dynamic-main">
      <div class="dynamic-content">
        <div class="dynamic-top">
          <div class="dynamic-top-title">
            <!-- <img src="../../assets/title_jqrd.png" /> -->
            <div>{{ this.topic }}</div>
            <div
              class="dynamic-top-title-bar"
              :class="{ ssbar: navssbar }"
            ></div>
          </div>
        </div>
      </div>
      <!-- 动态列表 -->
      <div class="content">
        <!-- 左侧时间轴 -->
        <div class="year" :class="{ 'year-fix-nav': navBarFixed }">
          <div
            class="nav"
            v-for="(item, index) in yearList"
            :key="index"
            @click="jump(index)"
          >
            <div class="nav-progress">
              <img
                :class="
                  yearNavSelect == index
                    ? 'nav-progress-select'
                    : 'nav-progress-unselect'
                "
                :src="
                  yearNavSelect == index
                    ? require('../../assets/icon_sjz_xz.png')
                    : require('../../assets/icon_sjz_wxz.png')
                "
              />
              <div
                class="nav-progress-line"
                v-if="index < yearList.length - 1"
              ></div>
              <span
                :class="yearNavSelect == index ? 'nav-year-select' : 'nav-year'"
                >{{ item }}</span
              >
            </div>
          </div>
        </div>
        <!-- 右侧列表 -->
        <div class="wrapper" :class="{ 'wrap-fix-nav': navBarFixed }">
          <div class="section" v-for="(item, index) in list" :key="index">
            <div class="section-year">{{ item.year }}</div>
            <div
              v-for="(item1, index1) in item.news"
              :key="index1"
              @click="gotoDetails(item1.id)"
              style="cursor: pointer"
            >
              <div class="section-content">{{ item1.newsTopic }}</div>
              <div class="section-date">{{ item1.newsDate | formatTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsResultes, visited } from "../../api/index";
export default {
  name: "hotspot",
  data() {
    return {
      startScroll: false, //点击标签时 不判断滚动锚点
      scroll: "",
      navBarFixed: false,
      topicCode: 1006,
      yearNavSelect: 0,
      newsResults: [],
      yearList: [],
      list: [],
      id: "",
      topic: "",
      navssbar: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.visit();
    this.show();
    this.$nextTick(() => {
      window.scrollTo({
        top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
      });
    });
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  filters: {
    /* 去除html标签 */
    clearHtmlCode(val) {
      return val.replace(/<[^>]+>/g, "");
    },
    /* 时间格式转换 */
    formatTime(val) {
      if (val) {
        return val.replace(/-/g, ".").substring(0, 10);
      } else {
        return "";
      }
    },
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  watch: {
    scroll: function () {
      this.loadSroll();
    },
  },
  methods: {
    visit() {
      this.visiting();
    },
    visiting() {
      visited(this.topicCode)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    /* 调用接口 */
    show() {
      if (this.id == 3) {
        this.topic = "企业新闻";
        getNewsResultes(this.id)
          .then((res) => {
            //console.log("最新成绩/近期热点", res);
            this.newsResults = res.data.data;
            let tempYearArr = [];
            for (let i in this.newsResults) {
              tempYearArr.push(this.newsResults[i].newsDate.substring(0, 4));
            }
            tempYearArr = Array.from(new Set(tempYearArr));
            this.yearList = tempYearArr;

            let tempNewArr = [];
            for (let i in this.yearList) {
              let tempObj = { year: "", news: [] };
              tempObj.year = this.yearList[i];
              for (let j in this.newsResults) {
                if (
                  this.newsResults[j].newsDate.substring(0, 4) == tempObj.year
                ) {
                  tempObj.news.push(this.newsResults[j]);
                }
              }
              tempNewArr.push(tempObj);
            }
            this.list = tempNewArr;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.id == 4) {
        this.topic = "视界热点";
        getNewsResultes(this.id)
          .then((res) => {
            // console.log("最新成绩/近期热点", res.data.data);
            this.newsResults = res.data.data;

            let tempYearArr = [];
            for (let i in this.newsResults) {
              tempYearArr.push(this.newsResults[i].newsDate.substring(0, 4));
            }
            tempYearArr = Array.from(new Set(tempYearArr));
            this.yearList = tempYearArr;

            let tempNewArr = [];
            for (let i in this.yearList) {
              let tempObj = { year: "", news: [] };
              tempObj.year = this.yearList[i];
              for (let j in this.newsResults) {
                if (
                  this.newsResults[j].newsDate.substring(0, 4) == tempObj.year
                ) {
                  tempObj.news.push(this.newsResults[j]);
                }
              }
              tempNewArr.push(tempObj);
            }
            this.list = tempNewArr;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.id == 5) {
        this.topic = "聚焦新国货";
        this.navssbar = true;
        getNewsResultes(this.id)
          .then((res) => {
            // console.log("最新成绩/近期热点", res.data.data);
            this.newsResults = res.data.data;

            let tempYearArr = [];
            for (let i in this.newsResults) {
              tempYearArr.push(this.newsResults[i].newsDate.substring(0, 4));
            }
            tempYearArr = Array.from(new Set(tempYearArr));
            this.yearList = tempYearArr;

            let tempNewArr = [];
            for (let i in this.yearList) {
              let tempObj = { year: "", news: [] };
              tempObj.year = this.yearList[i];
              for (let j in this.newsResults) {
                if (
                  this.newsResults[j].newsDate.substring(0, 4) == tempObj.year
                ) {
                  tempObj.news.push(this.newsResults[j]);
                }
              }
              tempNewArr.push(tempObj);
            }
            this.list = tempNewArr;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    /* 跳转详情 */
    gotoDetails(id) {
      this.$router.push({ path: "/NewsDetails", query: { id: id } });
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop =
        document.querySelector("#dynamic-section").offsetHeight +
        document.getElementsByClassName("nav-bg1")[0].offsetHeight -
        1;
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    jump(index) {
      var that = this;
      this.startScroll = true;
      this.yearNavSelect = index;
      let jump = document.getElementsByClassName("section");
      let total = jump[index].offsetTop;
      window.scrollTo({
        top: total - 100,
        behavior: "smooth",
      });
      setTimeout(() => {
        that.startScroll = false;
      }, 600);
      // console.log(document.getElementsByClassName("nav-year")[index].offsetTop);
    },
    loadSroll: function () {
      /* 吸顶栏定位 */
      if (!this.startScroll) {
        var self = this;
        var sections = document.getElementsByClassName("section");
        for (var i = sections.length - 1; i >= 0; i--) {
          if (self.scroll >= sections[i].offsetTop - 100) {
            this.yearNavSelect = i;
            break;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
#dynamic {
  width: 100%;
  margin: 0;
  padding: 0;
}
#dynamic-section {
  width: 100%;
  border-bottom: 1px solid #b8b8b8;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 2;
}
.ssbar {
  width: 145px !important;
}
.dynamic-fix-nav {
  position: fixed;
  top: 0;
  z-index: 99;
}
.dynamic-section {
  display: flex;
  justify-content: flex-start;
  height: 54px;
  background-color: white;
  width: 1280px;
  margin: 0 auto;
}
#dynamic-section li {
  font: 14px/40px "";
  padding: 0px 3px;
  text-align: center;
  height: 52px;
  line-height: 52px;
  margin-left: 72px;
  font-size: 17px;
  font-family: siyuan-Regular;
  font-weight: 400;
  color: #666666;
}
.dynamic-section-select {
  border-bottom: 3px solid #0075ff;
  border-radius: 2px;
}
.dynamic-main {
  width: 100%;
  background: #f3f3f3;
}
.dynamic-content {
  width: 1280px;
  margin: 0px auto;
  padding: 57px 0px 27px 0px;
  position: relative;
}
.dynamic-content2 {
  width: 1280px;
  margin: 0px auto;
  margin-bottom: 129px;
  padding-top: 27px;
  position: relative;
}
.dynamic-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 72px;
}
.dynamic-top-title {
  font: bold 32px/33px "siyuan-Regular";
  color: #333333;
  display: flex;
  flex-direction: column;
}
.dynamic-top-title img {
  width: 111px;
}
.dynamic-top-title-bar {
  width: 113px;
  height: 4px;
  background: #0075ff;
  margin-left: 25px;
  margin-top: 10px;
  border-radius: 2px;
}
.dynamic-top-right {
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 400;
  font-family: "siyuan-Regular";
}
.dynamic-top-right img {
  width: 13px;
  margin-left: 10px;
}

.content {
  display: flex;
  padding: 24px 0px 201px 0px;
  margin: 0px auto;
  width: 1000px;
}
.year {
  display: flex;
  flex-direction: column;
  /* height: 500px;
  overflow-x: hidden;
  overflow-y: scroll; */
}
.year-fix-nav {
  position: fixed;
  top: 100px;
  z-index: 99;
}
.year::-webkit-scrollbar {
  display: none;
}
.nav {
  display: block;
  text-align: center;
}
.nav-year {
  color: #333333;
  font-size: 19px;
  position: absolute;
  left: 24px;
  top: -10px;
  cursor: pointer;
}
.nav-year-select {
  color: #333333;
  font-size: 21px;
  font-weight: bold;
  position: absolute;
  left: 24px;
  top: -6px;
  cursor: pointer;
}
.nav-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav-progress-select {
  width: 16px;
}
.nav-progress-unselect {
  width: 10px;
}
.nav-progress-line {
  height: 80px;
  width: 2px;
  background: #999999;
}
.wrapper {
  margin-left: 100px;
}
.wrap-fix-nav {
  margin-left: 116px;
}
.section {
  display: flex;
  flex-direction: column;
}
.section-year {
  font-size: 21px;
  line-height: 20px;
  color: #333333;
  font-weight: bold;
  margin: 27px 0px;
}
.section-content {
  font-size: 20px;
  line-height: 21px;
  color: #333333;
  font-weight: bold;
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.section-date {
  font-size: 12px;
  line-height: 13px;
  color: #333333;
  margin: 18px 0px 27px 0px;
}
</style>
