<template>
  <div class="dispark">
    <h3>商家入驻信息登记表</h3>
    <div class="dispark-bar"></div>
    <div class="from">
      <li v-for="item in arr" :key="item.id">
        <span class="span" :id="item.id">{{ item.name }}</span>
        <input type="text" v-model="item.spaceVal" class="input" />
      </li>
      <li>
        <span class="span">验证码</span>
        <div class="code">
          <input
            type="text"
            class="input-verification"
            v-model="authCode"
            placeholder="区分大小写"
          />
          <span @click="refreshCode()" class="verification"
            ><s-Identify :identifyCode="identifyCode"></s-Identify
          ></span>
        </div>
      </li>
      <div class="from-btn">
        <input type="button" value="提交" @click="subEnter()" />
        <input type="buttom" value="清空" @click="empty()" />
      </div>
    </div>
  </div>
</template>
<script>
import SIdentify from "./identify.vue";

import { verify, verifys } from "../../api/index";
export default {
  data() {
    return {
      identifyCodes:
        "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM0123456789",
      identifyCode: "", //找回密码图形验证码
      authCode: "",
      arr: [],
      arr1: [],
      list: [],
      phones: "false",
      indetityes: "false",
      blank: "false",
    };
  },
  created() {
    this.show();
  },
  components: {
    "s-Identify": SIdentify,
  },
  watch: {
    identifyCode(v) {
      this.isDebugLogin && (this.loginForm.code = v);
    },
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 5);
  },
  methods: {
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 5);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    show() {
      verify()
        .then((res) => {
          // console.log(res);
          this.arr = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    empty() {
      this.arr.forEach((item) => {
        item.spaceVal = "";
      });
    },
    subEnter() {
      this.arr.forEach((item) => {
        this.list.push({
          spaceVal: item.spaceVal,
          id: item.id,
          parentId: item.parentId,
          name: item.name,
        });
      });
      this.arr.forEach((item) => {
        if (item.type == 7) {
          let reg = /^[1][3,4,5,7,8][0-9]{9}$/;
          let flag = reg.test(item.spaceVal);
          if (flag) {
            this.phones = true;
          } else {
            this.phones = false;
          }
        }
        if (item.type == 8) {
          let reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
          let flag = reg.test(item.spaceVal);
          if (flag) {
            this.indetityes = true;
          } else {
            this.indetityes = false;
          }
        }
        if (item.spaceVal != null && item.spaceVal != "") {
          this.arr1.push("1");
        }
      });
      // console.log(this.arr1);
      // console.log(this.arr);
      if (this.arr1.length != this.arr.length) {
        this.blank = false;
      } else {
        this.blank = true;
      }
      if (this.blank == true && this.authCode != null && this.authCode != "") {
        if (
          this.phones == true &&
          this.indetityes == true &&
          this.authCode == this.identifyCode
        ) {
          verifys(this.list)
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
          this.arr1 = [];
          this.arr.forEach((item) => {
            item.spaceVal = "";
          });
        } else if (
          this.indetityes == true &&
          this.phones == false &&
          this.authCode == this.identifyCode
        ) {
          alert("电话号码不正确");
          this.arr1 = [];
          this.arr.forEach((item) => {
            item.spaceVal = "";
          });
        } else if (
          this.indetityes == false &&
          this.phones == true &&
          this.authCode == this.identifyCode
        ) {
          alert("身份证号不正确");
          this.arr1 = [];
          this.arr.forEach((item) => {
            item.spaceVal = "";
          });
        } else if (
          this.authCode == this.identifyCode &&
          this.indetityes == false &&
          this.phones == false
        ) {
          alert("身份证号，电话号码都不正确");
          this.arr1 = [];
          this.arr.forEach((item) => {
            item.spaceVal = "";
          });
        } else if (
          this.authCode != this.identifyCode &&
          this.indetityes == false &&
          this.phones == false
        ) {
          alert("身份证号，电话号码，验证码都不正确");
          this.arr1 = [];
          this.arr.forEach((item) => {
            item.spaceVal = "";
          });
        } else if (
          this.authCode != this.identifyCode &&
          this.indetityes == false &&
          this.phones == true
        ) {
          alert("身份证号，验证码都不正确");
          this.arr1 = [];
          this.arr.forEach((item) => {
            item.spaceVal = "";
          });
        } else if (
          this.authCode != this.identifyCode &&
          this.indetityes == true &&
          this.phones == false
        ) {
          alert("电话号码，验证码都不正确");
          this.arr1 = [];
          this.arr.forEach((item) => {
            item.spaceVal = "";
          });
        } else if (
          this.authCode != this.identifyCode &&
          this.indetityes == true &&
          this.phones == true
        ) {
          alert("验证码不正确");
          this.arr1 = [];
          this.arr.forEach((item) => {
            item.spaceVal = "";
          });
        }
      } else {
        alert("请输入完整");
        this.arr1 = [];
        this.arr.forEach((item) => {
          item.spaceVal = "";
        });
      }
    },
  },
};
</script>
<style scoped>
.dispark {
  width: 100%;
  padding-bottom: calc(50px * 2 / 3);
  background-color: rgb(249, 249, 249);
}
.dispark h3 {
  font-size: calc(40px * 2 / 3);
  color: #000000;
  padding: calc(96px * 2 / 3) 0 0 calc(110px * 2 / 3);
}
.dispark .dispark-bar {
  width: calc(370px * 2 / 3);
  height: calc(4px * 2 / 3);
  background: #0075ff;
  margin-left: 100px;
  margin-top: 10px;
  border-radius: 2px;
}
.from {
  width: calc(1700px * 2 / 3);
  margin: calc(50px * 2 / 3) auto 0;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-top: calc(50px * 2 / 3);
}
.from li {
  width: calc(1000px * 2 / 3);
  height: calc(70px * 2 / 3);
  margin: 20px auto;
}
.from li {
  display: flex;
  justify-content: space-between;
}
.from li .input {
  box-sizing: border-box;
  width: calc(780px * 2 / 3);
  height: 40px;
  font: calc(25px * 2 / 3) / 40px "siyuan-Regular";
  padding-left: 12px;
}
.from li .span {
  font: calc(27px * 2 / 3) / 40px "siyuan-Regular";
}
.from .from-btn {
  width: calc(540px * 2 / 3);
  margin: calc(50px * 2 / 3) auto;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.from .from-btn input {
  width: 120px;
  font: calc(27px * 2 / 3) / 40px "siyuan-Regular";
  text-align: center;
  border: none;
  border-radius: 5%;
  cursor: pointer;
}
.from .from-btn input:nth-of-type(1) {
  background-color: rgb(0, 181, 255);
}
.from .from-btn input:nth-of-type(2) {
  background-color: #ccc;
}
.from li .code {
  width: calc(780px * 2 / 3);
  height: calc(70px * 2 / 3);
  display: flex;
  justify-content: flex-start;
}
.from li .code .verification {
  margin-left: 50px;
  cursor: pointer;
}
.from li .code .input-verification {
  width: 240px;
  height: 40px;
  font: calc(25px * 2 / 3) / 40px "siyuan-Regular";
  padding-left: 12px;
}
</style>
