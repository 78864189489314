<template>
  <div id="dynamic">
    <div id="dynamic-section" :class="{ 'dynamic-fix-nav': navBarFixed }">
      <div class="dynamic-section">
        <li class="dynamic-section-select">视频资料</li>
      </div>
    </div>
    <!-- 视频资料 -->
    <div class="content">
      <div style="padding: 24px 0px 133px 0px; width: 1280px; margin: 0 auto">
        <div class="dynamic-media-content">
          <div
            v-for="(item, index) in videoList"
            :key="index"
            @click="showVideo(index)"
            style="position: relative; cursor: pointer"
          >
            <img class="mediaImg" :src="item.filePath" />
            <img class="videoplay" src="../../assets/play-icon.png" />
            <div class="mediaTitle">{{ item.newsTopic }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频预览 -->
    <div v-if="showVideoFlag">
      <div class="video-pop" style="display: block">
        <div class="pop-bg" @click.stop="closeModule()"></div>
        <div class="pop-box">
          <img
            class="close"
            src="../../assets/close.png"
            @click.stop="closeModule()"
          />
          <video
            width="100%"
            loop=""
            preload="auto"
            controls=""
            autoplay=""
            :src="videoList[showVideoIndex].videoPath"
            class="video"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ipHeader, getVideoList, visited } from "../../api/index";
export default {
  name: "activity",
  data() {
    return {
      topicCode: 1008,
      navBarFixed: false,
      showVideoFlag: false, //显示视频预览模块
      showVideoIndex: 0, //选择的视频资料

      videoList: [],
    };
  },
  created() {
    this.visit();
    this.show();
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },

  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  watch: {},
  methods: {
    visit() {
      this.visiting();
    },
    visiting() {
      visited(this.topicCode)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    /* 调用接口 */
    show() {
      /* 视频资料 */
      getVideoList()
        .then((res) => {
          // console.log("视频资料", res.data.data);
          this.videoList = res.data.data;
          this.scrolltoTop();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /* 图片地址转换 */
    carouselImg(item) {
      let url = `${ipHeader}nsc/file${item}`;
      return url;
    },

    scrolltoTop() {
      this.$nextTick(() => {
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
        });
      });
    },

    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop =
        document.getElementById("dynamic-section").offsetHeight +
        document.getElementsByClassName("nav-bg1")[0].offsetHeight -
        1;
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    /* 视频预览 */
    showVideo(index) {
      this.showVideoFlag = true;
      this.showVideoIndex = index;
    },
    /* 关闭弹窗 */
    closeModule() {
      this.showVideoFlag = false;
    },
  },
};
</script>
<style scoped>
#dynamic {
  width: 100%;
  margin: 0;
  padding: 0;
}
#dynamic-section {
  width: 100%;
  border-bottom: 1px solid #b8b8b8;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 2;
}
.dynamic-fix-nav {
  position: fixed;
  top: 0;
  z-index: 99;
}
.dynamic-section {
  display: flex;
  justify-content: flex-start;
  height: 54px;
  background-color: white;
  width: 1280px;
  margin: 0 auto;
}
#dynamic-section li {
  font: 14px/40px "";
  padding: 0px 3px;
  text-align: center;
  height: 52px;
  line-height: 52px;
  margin-left: 72px;
  font-size: 17px;
  font-family: siyuan-Regular;
  font-weight: 400;
  color: #666666;
}
.dynamic-section-select {
  border-bottom: 3px solid #0075ff;
  border-radius: 2px;
}
.dynamic-media-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
  padding: 0px 72px;
}
.dynamic-media-content::after {
  content: "";
  width: 333px;
}
.mediaImg {
  width: 333px;
  height: 200px;
  margin-top: 33px;
}

.mediaTitle {
  font-size: 16px;
  line-height: 17px;
  color: #333333;
  font-weight: 400;
  margin-top: 8px;
  width: 333px;
}
.videoplay {
  position: absolute;
  width: 54px;
  height: 54px;
  top: 50%;
  margin-top: -27px;
  left: 50%;
  margin-left: -27px;
}
.content {
  width: 100%;
}
/* 视频模块 */
.video-pop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: none;
}
.video-pop .pop-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}
.video-pop .pop-box {
  width: 680px;
  height: 383px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background: #000;
  box-sizing: border-box;
}
.video-pop .pop-box .close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0px;
  top: -40px;
  cursor: pointer;
}
.video-pop .pop-box .video {
  width: 100%;
  height: 100%;
}
</style>
