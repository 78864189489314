<template>
  <div class="dynamic">
    <div id="dynamic-section" :class="{ 'dynamic-fix-nav': navBarFixed }">
      <div class="dynamic-section">
        <li class="dynamic-section-select" @click="navBarSelect(0)">
          精品案例
        </li>
        <li @click="navBarSelect(1)">企业新闻</li>
        <li @click="navBarSelect(2)">视界热点</li>
        <li @click="navBarSelect(3)">聚焦新国货</li>
      </div>
    </div>
    <!-- 精品案例 -->
    <div class="dynamic-main">
      <div id="cases" style="height: 80px"></div>
      <div class="dynamic-content">
        <div class="dynamic-top">
          <div class="dynamic-top-title">
            <!-- <img src="../../assets/title_jqrd.png" /> -->
            <div>精品案例</div>
            <div class="dynamic-top-title-bar"></div>
          </div>
        </div>
        <div class="dynamic-latest">
          <div
            class="dynamic-latest-item"
            v-for="(item, index) in newsResults"
            :key="index"
            @click="gotoDetails(item.id)"
          >
            <img class="dynamic-latest-item-img" v-lazy="item.absolutePath" />
            <div class="dynamic-latest-item-text">
              <span class="dynamic-latest-item-title">{{
                item.newsTopic
              }}</span>
              <span class="dynamic-latest-item-date">{{
                item.newsDate | formatTime
              }}</span>
            </div>
          </div>
          <div
            class="dynamic-latest-item"
            v-if="newsResults.length % 4 == 1"
          ></div>
          <div
            class="dynamic-latest-item"
            v-if="newsResults.length % 4 == 1"
          ></div>
          <div
            class="dynamic-latest-item"
            v-if="newsResults.length % 4 == 1"
          ></div>
          <div
            class="dynamic-latest-item"
            v-if="newsResults.length % 4 == 2"
          ></div>
          <div
            class="dynamic-latest-item"
            v-if="newsResults.length % 4 == 2"
          ></div>
          <div
            class="dynamic-latest-item"
            v-if="newsResults.length % 4 == 3"
          ></div>
        </div>
      </div>
    </div>
    <div class="section-title">
      <span class="section-title-cn">生态伙伴</span
      ><span class="section-title-en">ECOLOGICAL PARTNERS</span>
    </div>
    <div class="logo">
      <div
        class="logo-item"
        v-for="(item, index) in logoArray"
        :key="index"
        @click="jumpto(item.link, item.linkAddress)"
        :style="{ cursor: item.link === 0 ? '' : 'pointer' }"
      >
        <img :src="item.absolutePath" />
      </div>
    </div>
  </div>
</template>
<script>
import { getResultNews, logoImage } from "../../api/index";
export default {
  name: "cases",

  data() {
    return {
      startScroll: false, //点击标签时 不判断滚动锚点
      navBarFixed: false,
      navBarSelected: 0,
      selector: "",
      params: 10,
      newsResults: [],
      logoArray: [],
    };
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },

  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
    // localStorage.removeItem("toId");
  },
  created() {
    this.show();
    this.$nextTick(() => {
      if (localStorage.getItem("toId")) {
        // this.getlocal();
      } else {
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
        });
      }
    });
  },
  filters: {
    /* 去除html标签 */
    clearHtmlCode(val) {
      val = val.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
      val = val.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
      val = val.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
      val = val.replace(/&nbsp;/gi, ""); //去掉&nbsp;
      return val;
    },
    /* 时间格式转换 */
    formatTime(val) {
      if (val) {
        return val.replace(/-/g, ".").substring(0, 10);
      } else {
        return "";
      }
    },
  },
  methods: {
    jumpto(link, linkAddress) {
      if (link === 0) {
        return;
      } else {
        window.open(linkAddress);
      }
    },
    show() {
      getResultNews(this.params)
        .then((res) => {
          this.newsResults = res.data.data;
          // console.log("案例", res);
          this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
      logoImage()
        .then((res) => {
          //console.log(5, res);
          this.logoArray = res.data.data;
          // this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoDetails(id) {
      this.$router.push({ path: "/NewsDetails", query: { id: id } });
    },
    /* 获取跳转的锚点滚动 */
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId); //如果对应id存在，就跳转
      if (selectId && toElement) {
        toElement.scrollIntoView(true);
        localStorage.setItem("toId", "");
      }
    },
    /* 跳转锚点 */
    gotoSelectId() {
      this.$nextTick(() => {
        if (localStorage.getItem("toId")) {
          this.getlocal();
        } else {
          window.scrollTo({
            top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
          });
        }
      });
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop =
        document.getElementById("dynamic-section").offsetHeight +
        document.getElementsByClassName("nav-bg1")[0].offsetHeight -
        1;
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    /* 吸顶栏点击 */
    navBarSelect(index) {
      this.navBarSelected = index;
      if (index == 0) {
        this.selector = "#cases";
        let total = this.$el.querySelector(this.selector).offsetTop;
        let distance = document.documentElement.scrollTop;

        this.startScroll = true;
        if (total > distance) {
          window.scrollTo({
            top: total,
            behavior: "smooth",
          });
        } else {
          window.scrollTo({
            top: total,
            behavior: "smooth",
          });
        }
      } else {
        let selectID = "";
        if (index == 1) {
          selectID = "latest";
        }
        if (index == 2) {
          selectID = "news";
        }
        if (index == 3) {
          selectID = "focus";
        }
        localStorage.setItem("toId", selectID);
        this.$router.push({ path: "/Dynamic" });
      }
    },
  },
};
</script>
<style scoped>
#dynamic {
  width: 100%;
  margin: 0;
  padding: 0;
}
#dynamic-section {
  width: 100%;
  border-bottom: 1px solid #b8b8b8;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 2;
}
.dynamic-section {
  display: flex;
  justify-content: flex-start;
  height: 54px;
  background-color: white;
  width: 1280px;
  margin: 0 auto;
}
#dynamic-section li {
  font: 16px/40px "";
  padding: 0px 3px;
  text-align: center;
  height: 52px;
  line-height: 52px;
  margin-left: 73px;
  font-size: 17px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}
.dynamic-section-select {
  border-bottom: 3px solid #0075ff;
  border-radius: 2px;
}

.dynamic-fix-nav {
  position: fixed;
  top: 0;
  z-index: 99;
}
/* .dynamic-main {
  min-height: 600px;
} */
.dynamic-content {
  width: 1280px;
  margin: 0px auto;
  padding: 0px 0px 27px 0px;
  position: relative;
}
.dynamic-top {
  align-items: center;
  padding: 0px 73px;
}
.dynamic-top-title {
  font: bold 32px/33px "siyuan-Regular";
  color: #333333;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.dynamic-top-title-bar {
  width: 113px;
  height: 4px;
  background: #0075ff;
  margin-left: 25px;
  margin-top: 10px;
  border-radius: 2px;
}
/*  */
.dynamic-latest {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 73px;
}

.dynamic-latest-item {
  display: flex;
  flex-direction: column;
  width: 253px;
  cursor: pointer;
  margin-bottom: 44px;
}
.dynamic-latest-item-img {
  width: 253px;
  height: 142px;
  object-fit: cover;
  /* margin-top: 16px; */
  border-radius: 5px;
}
.dynamic-latest-item-text {
  width: 236px;
  height: 107px;
  background: #ffffff;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.13);
  opacity: 0.9;
  border-radius: 2px;
  margin-top: -48px;
  margin-left: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(0%);
  -ms-transform: translateY(0%); /* IE 9 */
  -moz-transform: translateY(0%); /* Firefox */
  -webkit-transform: translateY(0%); /* Safari 和 Chrome */
  -o-transform: translateY(0%); /* Opera */
  border-radius: 11px;
  transition: all 0.8s;
  -moz-transition: all 0.8s; /* Firefox 4 */
  -webkit-transition: all 0.8s; /* Safari and Chrome */
  -o-transition: all 0.8s; /* Opera */
}
.dynamic-latest-item-text:hover {
  transform: translateY(-10px);
  -ms-transform: translateY(-10px); /* IE 9 */
  -moz-transform: translateY(-10px); /* Firefox */
  -webkit-transform: translateY(-10px); /* Safari 和 Chrome */
  -o-transform: translateY(-10px); /* Opera */
  transition: all 0.8s;
  -moz-transition: all 0.8s; /* Firefox 4 */
  -webkit-transition: all 0.8s; /* Safari and Chrome */
  -o-transition: all 0.8s; /* Opera */
}
.dynamic-latest-item-title {
  /* margin-top: 14px; */
  font-size: 16px;
  line-height: 20px;
  font-family: "siyuan-Medium";
  color: #333333;
  padding: 16px 16px 0px 16px;
  /* font-weight: bold; */
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.dynamic-latest-item-content {
  margin-top: 17px;
  font-size: 14px;
  line-height: 17px;
  font-family: "siyuan-Normal";
  color: #333333;
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.dynamic-latest-item-date {
  margin-top: 19px;
  font-size: 12px;
  color: #333333;
  font-family: "siyuan-Regular";
  align-self: flex-end;
  padding: 0px 16px 16px 16px;
}
.section-title {
  display: flex;
  width: 1280px;
  margin: 72px auto 0;
  font-size: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.section-title-cn {
  font-size: 30px;
  font-family: "siyuan-Medium";
  font-weight: 500;
  color: #333333;
  line-height: 31px;
  text-align: center;
}
.section-title-en {
  font-size: 30px;
  font-family: "siyuan-Medium";
  font-weight: 400;
  color: #333333;
  line-height: 31px;
  opacity: 0.1;
  text-align: center;
  margin-top: -50px;
}
.logo {
  width: 1072px;
  margin: 72px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.logo-item {
  width: 110px;
  /* height: 52px; */
  margin: 0 12px 38px 12px;
}
.logo-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
