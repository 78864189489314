<template>
  <div class="list">
    <div id="list-section" :class="{ 'list-fix-nav': navBarFixed }">
      <div>
        <li
          :class="{ 'list-section-select': navBarSelected == 1 }"
          @click="jump(1)"
        >
          活动介绍
        </li>
        <li
          :class="{ 'list-section-select': navBarSelected == 2 }"
          @click="jump(2)"
        >
          更多报名入口
        </li>
      </div>
    </div>
    <div class="main ql-editor">
      <div class="mains" v-show="navBarSelected == 1">
        <div class="main-m" v-for="(item, index) in introduce" :key="index">
          <h3>{{ item.topic }}</h3>
          <div class="main-bar"></div>
          <div class="new" v-html="item.content"></div>
        </div>
        <div class="btn">
          <a href="https://jinshuju.net/f/nPiWPr" target="_blank"
            ><input type="buttom" value="活动报名入口"
          /></a>
        </div>
      </div>
      <div class="mains" v-show="navBarSelected == 2">
        <div class="main-m" v-for="(item, index) in apply" :key="index">
          <h3>{{ item.topic }}</h3>
          <div class="main-bar"></div>
          <div class="new" v-html="item.content"></div>
        </div>
        <div class="blank"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { listReferral, listEntrance, visited } from "../../api/index";
export default {
  data() {
    return {
      topicCode: 1005,
      navBarSelected: 1,
      navBarFixed: false,
      introduce: "",
      apply: "",
    };
  },
  created() {
    this.visit();
    this.referral();
    this.enterance();
    window.scrollTo({
      top: document.getElementsByClassName("nav-bg")[0].offsetHeight,
    });
  },
  methods: {
    visit() {
      this.visiting();
    },
    visiting() {
      visited(this.topicCode)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop =
        document.querySelector("#list-section").offsetHeight +
        document.getElementsByClassName("nav-bg")[0].offsetHeight -
        1;
      //console.log(offsetTop);
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    jump(index) {
      this.navBarSelected = index;
    },
    referral() {
      listReferral()
        .then((res) => {
          //console.log(res);
          this.introduce = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    enterance() {
      listEntrance()
        .then((res) => {
          //console.log(res);
          this.apply = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },

  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
};
</script>
<style scoped>
#list-section {
  height: 55px;
  width: 100%;
  border-bottom: 1px solid #b8b8b8;
  background-color: #fff;
}
#list-section div {
  height: 55px;
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}
#list-section div li {
  font: 16px / 53px "siyuan-Regular";
  height: 53px;
  text-align: center;
  margin: 0 calc(67px * 2 / 3);
  color: #666666;
}
#list-section div li:nth-of-type(1) {
  margin-left: calc(134px * 2 / 3);
}
#list-section div .list-section-select {
  border-bottom: 3px solid #0075ff;
  border-radius: 2px;
}
.list-fix-nav {
  position: fixed;
  top: 0;
  z-index: 99;
}
.main {
  width: 100%;
}
.main .mains {
  width: 1280px;
  margin: 0 auto;
}
.main .mains .main-m {
  position: relative;
}
.main .mains .main-m h3 {
  font: calc(40px * 2 / 3) / calc(250px * 2 / 3) "siyuan-Regular";
  text-align: center;
}
.main .mains .main-m .main-bar {
  width: calc(170px * 2 / 3);
  height: calc(4px * 2 / 3);
  background: #0075ff;
  border-radius: 2px;
  position: absolute;
  top: 100px;
  left: calc(920px * 2 / 3);
}
.main .mains .main-m .new {
  width: 800px;
  margin: 0 auto;
  font-size: calc(20px * 2 / 3);
}
.btn input {
  width: calc(200px * 2 / 3);
  height: 40px;
  color: #fff;
  text-align: center;
  font: 20px/40px "siyuan-Regular";
  margin: calc(100px * 2 / 3) 0 calc(80px * 2 / 3) calc(860px * 2 / 3);
  background-color: rgb(0, 181, 255);
  border: none;
  border-radius: 2%;
  cursor: pointer;
}
.blank {
  height: 300px;
}
</style>
