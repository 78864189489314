<template>
  <div class="platform">
    <div id="platform-section" :class="{ 'platform-fix-nav': navBarFixed }">
      <div>
        <li
          :class="{ 'platform-section-select': navBarSelected == 1 }"
          @click="run(1)"
        >
          视界观
        </li>
        <li
          :class="{ 'platform-section-select': navBarSelected == 2 }"
          @click="run(2)"
        >
          新国货电商
        </li>
      </div>
    </div>
    <div :class="{ 'fix-div': navBarFixed }"></div>
    <div v-show="navBarSelected == 2">
      <div class="goods-intro">
        <div class="goods-intro-topic">
          <span class="goods-intro-topic-cn">新国货电商</span>
          <span class="goods-intro-topic-en">ABOUT&nbsp;&nbsp;US</span>
          <div class="goods-intro-topic-main">
            新国货电商，倡导“品牌树格
            消费互联”，构建大屏端、移动端、“视界优品”门店三大营销场景，形成省、市、县三级电商运营新模式，孵化100+“视界优品”王牌产品，为区域品牌、行业品牌、企业品牌深度赋能，是新国货品牌供应链融合服务平台。
          </div>
        </div>
        <div class="goods-intro-image">
          <div class="goods-intro-image-child">
            <img src="../../assets/xghLOGO/jsxgh.jpg" alt="" /><br />
            <div class="span">公众号-江苏新国货</div>
          </div>
          <div class="goods-intro-image-child">
            <img src="../../assets/xghLOGO/sjyp.jpg" alt="" /><br />
            <div class="span">小程序-视界优品</div>
          </div>
        </div>
      </div>
      <div class="goods-ndd">
        <div class="goods-ndd-left">
          <div class="goods-ndd-img">
            <img src="../../assets/nddlogo.png" alt="" />
          </div>
          <div class="goods-ndd-topic">
            <div class="goods-ndd-topic-cn">核心优势</div>
            <div class="goods-ndd-topic-en">CORE ADVANTAGES</div>
            <div class="goods-ndd-topic-bar"></div>
          </div>
          <div class="goods-ndd-main">
            深度零售接触点<br />
            全效品牌孵化器<br />
            立体式营销网络<br />
          </div>
        </div>
        <div class="goods-ndd-right">
          <div class="goods-ndd-child">
            <div class="goods-ndd-child-topic">国货领航</div>
            <div class="goods-ndd-child-main">
              国潮之光 匠心质造<br />「爆款中国」聚合地
            </div>
          </div>
          <div class="goods-ndd-child">
            <div class="goods-ndd-child-topic">乡村振兴</div>
            <div class="goods-ndd-child-main">
              品牌强农 营销富民<br />培植农品新模式
            </div>
          </div>
          <div class="goods-ndd-child">
            <div class="goods-ndd-child-topic">优品100</div>
            <div class="goods-ndd-child-main">
              百家好物 百分生活<br />开掘品牌成长力
            </div>
          </div>
          <div class="goods-ndd-child">
            <div class="goods-ndd-child-topic">家乡好物</div>
            <div class="goods-ndd-child-main">
              自然本味 精准溯源<br />把回忆装进行囊
            </div>
          </div>
        </div>
      </div>
      <div class="goods-mode">
        <div class="goods-mode-left">
          <img
            src="../../assets/online.png"
            alt=""
            class="goods-mode-left-imgone"
          />
          <div class="goods-mode-left-one">线上 三大入口 一键直达</div>
          <img
            src="../../assets/offline.png"
            alt=""
            class="goods-mode-left-imgtwo"
          />
          <div class="goods-mode-left-two">
            线下 互动融合 精准营销
            1600个江苏有线各地营业厅视界优品实体专区、7000多名网格员深入社区群体
          </div>
        </div>
        <div class="goods-mode-right">
          <div class="goods-mode-topic-cn">
            <span class="topic-child-cn1">线上线下全域渗透</span>
            <span class="topic-child-cn2">品销合一</span>
          </div>
          <div class="goods-mode-topic-en">
            <span class="topic-child-en1">INTEGRATION OF PRODUCTS</span>
            <span class="topic-child-en2">AND SALES</span>
          </div>
          <div class="goods-mode-topic-bar"></div>
          <div class="goods-mode-main">
            <span class="mode-main-child">线上 三大入口 一键直达</span>
            <span class="mode-main-child">江苏有线电视商场</span>
            <span class="mode-main-child">视界观APP商场</span>
            <span class="mode-main-child">视界优品微信商城</span>
          </div>
        </div>
      </div>
      <div class="goods-trench">
        <div class="goods-trench-left">
          <div class="goods-trench-left-cn">
            <span class="trench-child-cn">多能力加持</span>
            <span class="trench-child-cn">全渠道发力</span>
          </div>
          <div class="goods-trench-left-en">ALL CHANNEL FORCE</div>
          <div class="goods-trench-left-bar"></div>
        </div>
        <div class="goods-trench-right">
          <span class="trench-right-child">政企合作</span>
          <span class="trench-right-child">高效优选精品团购</span>
          <span class="trench-right-child">大小屏联动 聚合流量</span>
          <span class="trench-right-child">聚焦圈层 满足多元消费</span>
        </div>
      </div>
      <div class="goods-firm">
        <div class="goods-firm-title">
          <span class="goods-firm-title-cn">生态伙伴</span>
          <span class="goods-firm-title-en">ECOLOGICAL PARTNERS</span>
        </div>
        <div class="goods-firm-logo">
          <div
            class="goods-firm-logo-item"
            v-for="(item, index) in logoArr"
            :key="index"
            @click="jumpto(item.link, item.linkAddress)"
            :style="{ cursor: item.link === 0 ? '' : 'pointer' }"
          >
            <img :src="item.absolutePath" />
          </div>
        </div>
      </div>
    </div>
    <div v-show="navBarSelected == 1">
      <div class="goods-intro">
        <div class="goods-intro-topic">
          <span class="sight-intro-topic-cn">视界观</span>
          <span class="goods-intro-topic-en">ABOUT&nbsp;&nbsp;US</span>
          <div class="goods-intro-topic-main">
            “视界观”移动客户端，倡导“科技未来
            品质生活”，构建内容生态共同体，吸引了包括政府部门、权威媒体单位、区域龙头企业及国内头部自媒体创作者在内的超500家机构及个人入驻，通过创作者、品牌、机构、平台合力共振，积蓄内容生态的“能量之源”，是长三角地区最具影响力的互联网内容生态平台之一。
          </div>
        </div>
        <div class="goods-intro-image">
          <div class="goods-intro-image-child">
            <img src="../../assets/xghLOGO/download.jpg" alt="" /><br />
            <div class="span">iOS下载</div>
          </div>
          <div class="goods-intro-image-child">
            <img src="../../assets/xghLOGO/download.jpg" alt="" /><br />
            <div class="span">安卓下载</div>
          </div>
        </div>
      </div>
      <div class="sight-ndd">
        <div class="goods-ndd-left">
          <div class="sight-ndd-img">
            <img src="../../assets/xghLOGO/icon.jpg" alt="" />
          </div>
          <div class="sight-ndd-topic-cn">板块及功能</div>
          <div class="sight-ndd-topic-en">PLATE AND</div>
          <div class="sight-ndd-topic-en1">FUNCTION</div>
          <div class="sight-ndd-topic-bar"></div>
        </div>
        <div class="sight-ndd-right">
          <div class="goods-ndd-child">
            <div class="sight-ndd-child-topic">专业视频板块 观世界</div>
            <div class="sight-ndd-child-main">
              向政府及企业客户提供官方、<br />权威、可信的传播渠道
            </div>
          </div>
          <div class="goods-ndd-child">
            <div class="sight-ndd-child-topic">电商板块 购实惠</div>
            <div class="sight-ndd-child-main">
              覆盖全省的O2O销售渠道，<br />与品牌方、供应链共建电商生态
            </div>
          </div>
          <div class="goods-ndd-child">
            <div class="sight-ndd-child-topic">用户投稿板块 上电视</div>
            <div class="sight-ndd-child-main">
              极具亲和力的UGC内容平台<br />大小屏打通，品牌联动
            </div>
          </div>
          <div class="goods-ndd-child">
            <div class="sight-ndd-child-topic">客户服务板块 有线VIP</div>
            <div class="sight-ndd-child-main">
              为有线电视用户提供线上营业厅、<br />专属权益等一站式服务
            </div>
          </div>
        </div>
      </div>
      <div class="sight-content">
        <div class="sight-content-main">
          <div class="sight-content-img">
            <img
              src="../../assets/content.png"
              alt=""
              class="sight-content-main-img"
            />
          </div>
          <div class="sight-content-main-childs">
            <div class="sight-content-main-child1">
              <div class="sight-content-child-topic">
                助力区域内容向全国传播
              </div>
              <div class="sight-content-child-main">
                覆盖全江苏每一个区县<br />江苏有线分子公司新媒体团队专属运作
              </div>
            </div>
            <div class="sight-content-main-child2">
              <div class="sight-content-child-topic">
                引领生活方式及消费理念新风尚
              </div>
              <div class="sight-content-child-main">
                300余名网红KOL入驻<br />100余个品牌及机构入驻
              </div>
            </div>
            <div class="sight-content-main-child3">
              <div class="sight-content-child-topic">权威、官方、高触达</div>
              <div class="sight-content-child-main">
                聚焦乡村振兴、国货品牌传播等热门领域<br />官方直播、慢直播、专题、活动等模块支持
              </div>
            </div>
          </div>
        </div>
        <div class="sight-content-topic-cn">内容传播</div>
        <div class="sight-content-topic-en">CONTENT</div>
        <div class="sight-content-topic-en1">DISSEMINATION</div>
        <div class="sight-content-topic-bar"></div>
      </div>
      <div class="sight-home">
        <div class="sight-home-left">
          <div class="sight-home-left-topic">
            <div class="sight-home-topic-cn">内容传播</div>
            <div class="sight-home-topic-cn">家乡号</div>
            <div class="sight-home-height"></div>
            <div class="sight-home-topic-en">CONTENT</div>
            <div class="sight-home-topic-en">DISSEMINATION</div>
            <div class="sight-home-topic-bar"></div>
          </div>
        </div>
        <div class="sight-home-right">
          <div class="sight-home-rl">
            <div class="sight-home-r11">
              最懂本土的媒体号团队-完全由江苏有线<br />
              78家分子公司的新媒体团队运营的本地<br />
              化自媒体号。
            </div>

            <div class="sight-home-r12">
              <div class="sight-home-child">
                <div class="sight-home-b">100</div>
                <div class="sight-home-c1">多个本地政务、生活、资讯号</div>
              </div>
              <div class="sight-home-child1">
                <div class="sight-home-b">600w</div>
                <div class="sight-home-c2">
                  累计覆盖<br />以上移动端订阅用户
                </div>
              </div>
              <div class="sight-home-child">
                <div class="sight-home-b">1400w</div>
                <div class="sight-home-c3">电视端区域受众</div>
              </div>
            </div>
            <div class="sight-home-rl3">
              <div class="sight-home-b">400</div>
              <div class="sight-home-c4">
                多个地方企业与品牌通过家乡号<br />在平台进行推广传播和销售转化
              </div>
            </div>
          </div>
          <div class="sight-home-rr"></div>
        </div>
      </div>
      <div class="sight-number">
        <div class="sight-number-left"></div>
        <div class="sight-number-right">
          <div class="sight-number-r1">
            <div class="sight-number-cbar"></div>
            <div class="sight-number-main">
              开放的内容创作生态，<br />多元的创作者激励及扶持
            </div>
          </div>
          <div class="sight-number-r2">
            <div class="sight-number-cbar"></div>
            <div class="sight-number-main">
              百余名智库专家及媒体机构入驻，<br />覆盖党政、新闻、科技、财经、教育多个领域
            </div>
          </div>
          <div class="sight-line"></div>
          <div class="sight-number-r3">
            <div class="sight-number-cbar"></div>
            <div class="sight-number-main">
              <div class="sight-home-bs">400+</div>
              网红KOL签约入驻<br />
              提高知识、生活、文旅、美食、游戏等垂类内容吸引力，KOL矩阵<br />
              全网粉丝覆盖量1亿＋
            </div>
          </div>
          <div class="sight-number-topic">
            <div class="sight-number-topic-cn">内容传播</div>
            <div class="sight-number-topic-cn">视界号</div>
            <div class="sight-number-height"></div>
            <div class="sight-number-topic-en">CONTENT</div>
            <div class="sight-number-topic-en">DISSEMINATION</div>
            <div class="sight-number-topic-bar"></div>
          </div>
        </div>
      </div>
      <div class="sight-official">
        <div class="sight-official-left">
          <div class="sight-official-left-topic">
            <div class="sight-home-topic-cn">内容传播</div>
            <div class="sight-home-topic-cn">官方号</div>
            <div class="sight-home-height"></div>
            <div class="sight-home-topic-en">CONTENT</div>
            <div class="sight-home-topic-en">DISSEMINATION</div>
            <div class="sight-home-topic-bar"></div>
          </div>
        </div>
        <div class="sight-official-right">
          <div class="sight-official-rl">
            <div class="sight-official-child1">
              “振兴号”以公益方式助力乡<br />
              村企业和企业家影响力打造
            </div>
            <div class="sight-official-child2">
              平台官方品牌榜单、企业调研<br />
              行、用户溯源游、体验官测评<br />
              等产品全维度助力成长型国货<br />
              影响力升级
            </div>
            <div class="sight-official-child">
              定制中短视频系列节目，以活<br />
              泼、低门槛的短视频形态进行<br />
              全网分发，快速打造10W+爆<br />
              款内容
            </div>
            <div class="sight-official-n">10W+</div>
          </div>
          <div class="sight-official-rr"></div>
        </div>
      </div>
      <div class="sight-serve">
        <div class="sight-serve-topic">
          <div class="sight-serve-left">整合营销<br />品牌服务</div>
          <div class="sight-serve-right">
            <div class="sight-serve-en">
              INTEGRATED MARKETING<br />BRAND SERVICE
            </div>
            <div class="sight-serve-bar"></div>
          </div>
        </div>
        <div class="sight-serve-img"></div>
        <div class="sight-serve-main">
          <div class="sight-serve-child">
            <div class="sight-child-topic">乡村振兴类</div>
            <div class="sight-child-mian">
              官方“振兴号”策划团队甄选优质乡镇企业<br />
              和案例进行乡村振兴公益报道和形象包装
            </div>
          </div>
          <div class="sight-serve-child">
            <div class="sight-child-topic1">国货领航类</div>
            <div class="sight-child-mian">
              平台官方品牌榜单、企业调研行、用户溯源游、<br />
              体验官测评等产品<br />
              全维度助力成长型国货影响力升级
            </div>
          </div>
          <div class="sight-serve-child">
            <div class="sight-child-topic">整合营销类</div>
            <div class="sight-child-mian">
              专业代运营、事件营销及大型宣推活动落地，<br />
              4A级策划、设计、视频团队全程服务
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { goodsImage } from "../../api/index";
export default {
  name: "platform",

  data() {
    return {
      topicCode: 1003,
      navBarFixed: false,
      navBarSelected: 1,
      startScroll: false,
      logoArr: [],
    };
  },
  created() {
    this.visit();
    if (sessionStorage.paltformNavBarSelected) {
      this.navBarSelected = parseInt(sessionStorage.paltformNavBarSelected);
      sessionStorage.removeItem("paltformNavBarSelected");
    } else {
      this.navBarSelected = 1;
    }
    this.$nextTick(() => {
      if (localStorage.getItem("toId")) {
        this.getlocal();
      } else {
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
        });
      }
    });
  },
  mounted() {
    this.$bus.$on("a", (input) => {
      this.navBarSelected = input;
      window.scrollTo({
        top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
      });
    });

    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  methods: {
    jumpto(link, linkAddress) {
      if (link === 0) {
        return;
      } else {
        window.open(linkAddress);
      }
    },
    visit() {
      goodsImage().then((res) => {
        console.log(res);
        this.logoArr = res.data.data;
      });
    },
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");

      //如果对应id存在，就跳转
      if (selectId) {
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
        });
        this.navBarSelected = selectId;
        localStorage.setItem("toId", "");
      }
    },
    run(index) {
      if (this.navBarSelected != index) {
        this.navBarSelected = index;
        sessionStorage.setItem("paltformNavBarSelected", index);
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
          behavior: "smooth",
        });
      } else {
        return;
      }
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop =
        document.querySelector("#platform-section").offsetHeight +
        document.getElementsByClassName("nav-bg1")[0].offsetHeight -
        1;
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
  },
};
</script>
<style>
.fix-div {
  height: 56px;
}
#platform-section {
  height: 55px;
  width: 100%;
  border-bottom: 1px solid #b8b8b8;
  background-color: #fff;
}
#platform-section div {
  height: 55px;
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}
#platform-section div li {
  font: 16px / 53px "siyuan-Regular";
  height: 53px;
  text-align: center;
  margin: 0 calc(67px * 2 / 3);
  color: #666666;
  cursor: pointer;
}
#platform-section div li:nth-of-type(1) {
  margin-left: calc(134px * 2 / 3);
}
#platform-section div .platform-section-select {
  border-bottom: 3px solid #0075ff;
  border-radius: 2px;
}
.platform-fix-nav {
  position: fixed;
  top: 0;
  z-index: 99;
}
.goods-intro {
  width: 1280px;
  margin: 0 auto;
  display: flex;
}
.goods-intro-topic {
  width: 780px;
  height: 300px;
  margin: 155px auto 0;
  font-size: 24px;
  position: relative;
}
.goods-intro-topic-cn {
  font-size: 30px;
  font-family: "siyuan-Medium";
  font-weight: 500;
  color: #333333;
  line-height: 31px;
  position: absolute;
  left: 111px;
}
.sight-intro-topic-cn {
  font-size: 30px;
  font-family: "siyuan-Medium";
  font-weight: 500;
  color: #333333;
  line-height: 31px;
  position: absolute;
  left: 141px;
}
.goods-intro-topic-en {
  font-size: 30px;
  font-family: "siyuan-Heavy";
  font-weight: 400;
  color: #333333;
  line-height: 31px;
  opacity: 0.1;
  position: absolute;
  left: 111px;
  top: -18px;
}
.goods-intro-topic-main {
  position: absolute;
  top: 89px;
  left: 81px;
  /* height: 73px; */
  font-size: 16px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  text-indent: 2em;
  text-align: justify;
}
.goods-intro-image {
  width: 500px;
  display: flex;
  margin: 161px 0 0 73px;
}
.goods-intro-image-child {
  margin-left: 27px;
}
.goods-intro-image-child img {
  width: 135px;
  height: 135px;
  /* background-color: aqua; */
}
.goods-intro-image-child .span {
  width: 135px;
  font-size: 16px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.goods-ndd {
  width: 1280px;
  margin: 0 auto 70px;
  height: 423px;
  display: flex;
}
.sight-ndd {
  width: 1280px;
  margin: 0 auto 20px;
  height: 423px;
  display: flex;
}
.goods-ndd-left {
  width: 282px;
  height: 423px;
  background: #3b3b3b;
  opacity: 0.8;
  position: relative;
}
.goods-ndd-img {
  position: absolute;
  top: 51px;
  left: 32px;
  width: 98px;
  /* height: 38px; */
}
.sight-ndd-img {
  position: absolute;
  top: 51px;
  left: 32px;
  width: 62px;
  height: 62px;
  background: #fff;
}
.sight-ndd-img img {
  width: 62px;
  height: 62px;
  border-radius: 4px;
}
.goods-ndd-img img {
  width: 98px;
  /* height: 38px; */
}
.goods-ndd-topic {
  width: 240px;
  position: absolute;
  top: 160px;
  left: 14px;
  position: relative;
}
.goods-ndd-topic-cn {
  font-size: 28px;
  font-family: "siyuan-Normal";
  /* font-weight: bold; */
  color: #ffffff;
  position: absolute;
  right: 0;
}
.sight-ndd-topic-cn {
  /* height: 24px; */
  font-size: 28px;
  font-family: "siyuan-Normal";
  /* font-weight: bold; */
  color: #ffffff;
  position: absolute;
  right: 49px;
  top: 173px;
}
.goods-ndd-topic-en {
  font-size: 24px;
  font-family: "siyuan-ExtraLight";
  /* font-weight: bold; */
  color: #ffffff;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 34px;
  white-space: nowrap;
}
.sight-ndd-topic-en {
  line-height: 30px;
  font-size: 24px;
  font-family: "siyuan-ExtraLight";
  color: #ffffff;
  opacity: 0.2;
  position: absolute;
  right: 49px;
  top: 214px;
}
.sight-ndd-topic-en1 {
  line-height: 30px;
  font-size: 24px;
  font-family: "siyuan-ExtraLight";
  color: #ffffff;
  opacity: 0.2;
  position: absolute;
  right: 49px;
  top: 242px;
}
.goods-ndd-topic-bar {
  width: 90px;
  height: 3px;
  background: #0075ff;
  border-radius: 1px;
  position: absolute;
  right: 0;
  top: 70px;
}
.sight-ndd-topic-bar {
  width: 90px;
  height: 3px;
  background: #0075ff;
  border-radius: 1px;
  position: absolute;
  right: 49px;
  top: 277px;
}
.goods-ndd-main {
  position: absolute;
  top: 270px;
  left: 142px;
  width: 120px;
  font-size: 16px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.goods-ndd-right {
  width: 998px;
  height: 423px;
  display: flex;
  background-image: url("../../assets/ndd.png");
}
.sight-ndd-right {
  width: 998px;
  height: 423px;
  display: flex;
  background: url("../../assets/plate.png") no-repeat 0 0/100% 100%;
}
.goods-ndd-child {
  width: 249.5px;
  height: 423px;
}
.goods-ndd-child-topic {
  margin-top: 36px;
  width: 249.5px;
  height: 21px;
  font-size: 20px;
  font-family: "siyuan-Regular";
  font-weight: bold;
  color: #ffffff;
  line-height: 44px;
  opacity: 0.8;
  text-align: center;
}
.sight-ndd-child-topic {
  margin-top: 36px;
  width: 249.5px;
  height: 16px;
  font-size: 20px;
  font-family: "siyuan-Regular";
  font-weight: bold;
  color: #ffffff;
  line-height: 44px;
  opacity: 0.8;
  text-align: center;
}
.goods-ndd-child-main {
  margin-top: 20px;
  width: 249.5px;
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  opacity: 0.8;
  text-align: center;
}
.sight-ndd-child-main {
  margin-top: 30px;
  width: 249.5px;
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  opacity: 0.8;
  text-align: center;
}
.goods-mode {
  width: 1280px;
  margin: 0 auto 70px;
  display: flex;
}
.goods-mode-left {
  width: 999px;
}
.goods-mode-left-imgone {
  width: 999px;
  height: 183px;
}
.goods-mode-left-one {
  width: 999px;
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #3f3a39;
  line-height: 30px;
  text-align: center;
  opacity: 0.8;
}
.goods-mode-left-imgtwo {
  width: 999px;
  height: 183px;
}
.goods-mode-left-two {
  width: 999px;
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #3f3a39;
  line-height: 30px;
  text-align: center;
  opacity: 0.8;
}
.goods-mode-right {
  width: 281px;
  /* height: 484px; */
  position: relative;
}
.goods-mode-topic-cn {
  position: absolute;
  top: 42px;
  right: 42px;
  position: relative;
}
.topic-child-cn1 {
  font-size: 24px;
  font-family: "siyuan-Normal";
  /* font-weight: bold; */
  color: #626262;
  line-height: 30px;
  position: absolute;
  right: 0;
}
.topic-child-cn2 {
  font-size: 24px;
  font-family: "siyuan-Normal";
  /* font-weight: bold; */
  color: #626262;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: 31px;
}
.goods-mode-topic-en {
  position: absolute;
  top: 109px;
  right: 42px;
  position: relative;
}
.topic-child-en1 {
  font-size: 16px;
  font-family: "siyuan-Regular";
  font-weight: 200;
  color: #626262;
  opacity: 0.2;
  position: absolute;
  right: 0;
}
.topic-child-en2 {
  font-size: 16px;
  font-family: "siyuan-Regular";
  font-weight: 200;
  color: #626262;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 20px;
}
.goods-mode-topic-bar {
  width: 90px;
  height: 3px;
  background: #0075ff;
  border-radius: 1px;
  position: absolute;
  top: 158px;
  right: 42px;
}
.goods-mode-main {
  position: absolute;
  top: 246px;
  right: 42px;
  position: relative;
}
.mode-main-child {
  font-size: 16px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #626262;
  line-height: 20px;
  position: absolute;
  right: 0;
}
.mode-main-child:nth-of-type(2) {
  top: 26px;
}
.mode-main-child:nth-of-type(3) {
  top: 52px;
}
.mode-main-child:nth-of-type(4) {
  top: 78px;
}
.goods-trench {
  width: 1280px;
  margin: 0 auto;
  display: flex;
}
.goods-trench-left {
  width: 280px;
  height: 423px;
  background: #3b3b3b;
  opacity: 0.8;
  position: relative;
}
.goods-trench-left-cn {
  position: absolute;
  top: 158px;
  right: 36px;
  position: relative;
}
.trench-child-cn {
  font-size: 28px;
  font-family: "siyuan-Normal";
  /* font-weight: bold; */
  color: #ffffff;
  /* line-height: 30px; */
  position: absolute;
  right: 0;
}
.trench-child-cn:nth-of-type(2) {
  top: 30px;
}
.goods-trench-left-en {
  font-size: 24px;
  font-family: "siyuan-ExtraLight";
  /* font-weight: bold; */
  color: #ffffff;
  opacity: 0.4;
  position: absolute;
  top: 224px;
  right: 36px;
  white-space: nowrap;
}
.goods-trench-left-bar {
  width: 90px;
  height: 3px;
  background: #0075ff;
  border-radius: 1px;
  position: absolute;
  top: 258px;
  right: 46px;
}
.goods-trench-right {
  width: 1000px;
  height: 423px;
  background-image: url("../../assets/trench.png");
  position: relative;
}
.trench-right-child {
  position: absolute;
  right: 42px;
  top: 77px;
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #626262;
  line-height: 19px;
  opacity: 0.8;
}
.trench-right-child:nth-of-type(2) {
  top: 96px;
}
.trench-right-child:nth-of-type(3) {
  top: 129px;
}
.trench-right-child:nth-of-type(4) {
  top: 148px;
}
.goods-firm {
  width: 1280px;
  margin: 72px auto 0;
}
.goods-firm-title {
  display: flex;
  font-size: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.goods-firm-title-cn {
  font-size: 30px;
  font-family: "siyuan-Medium";
  font-weight: 500;
  color: #333333;
  line-height: 31px;
  text-align: center;
}
.goods-firm-title-en {
  font-size: 30px;
  font-family: "siyuan-Medium";
  font-weight: 400;
  color: #333333;
  line-height: 31px;
  opacity: 0.1;
  text-align: center;
  margin-top: -50px;
}
.goods-firm-logo {
  width: 1072px;
  margin: 72px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.goods-firm-logo-item {
  margin: 0 12px 38px 12px;
  width: 110px;
  /* height: 52px; */
}
.goods-firm-logo-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sight-content {
  width: 1280px;
  margin: 0 auto 20px;
  height: 423px;
  position: relative;
}
.sight-content-img {
  position: absolute;
  left: 70px;
  top: 40px;
}
.sight-content-main-childs {
  width: 810px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 310px;
  left: 120px;
}
.sight-content-child-topic {
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  text-align: center;
}
.sight-content-child-main {
  font-size: 14px;
  font-family: "siyuan-Regular";
  color: #333333;
  line-height: 24px;
  text-align: center;
}
.sight-content-main-img {
  width: 900px;
  height: 256px;
}
.sight-content-topic-cn {
  position: absolute;
  right: 140px;
  top: 157px;
  font-size: 28px;
  font-family: "siyuan-Normal";
  color: #333333;
  line-height: 30px;
}
.sight-content-topic-en {
  position: absolute;
  right: 140px;
  top: 190px;
  font-size: 30px;
  font-family: "siyuan-Regular";
  font-weight: 200;
  color: #000000;
  opacity: 0.2;
}
.sight-content-topic-en1 {
  position: absolute;
  right: 140px;
  top: 220px;
  font-size: 30px;
  font-family: "siyuan-Regular";
  font-weight: 200;
  color: #000000;
  opacity: 0.2;
}
.sight-content-topic-bar {
  position: absolute;
  right: 140px;
  top: 264px;
  width: 90px;
  height: 3px;
  background: #0075ff;
  border-radius: 1px;
}
.sight-home {
  width: 1280px;
  height: 516px;
  margin: 0 auto;
  display: flex;
}
.sight-home-left {
  width: 275px;
  height: 516px;
  background: #3b3b3b;
  opacity: 0.8;
  position: relative;
}
.sight-home-left-topic {
  position: absolute;
  top: 190px;
  right: 45px;
  position: relative;
}
.sight-home-topic-cn {
  text-align: right;
  font-size: 28px;
  font-family: "siyuan-Normal";
  /* font-weight: bold; */
  color: #ffffff;
  line-height: 30px;
}
.sight-home-height {
  height: 10px;
}
.sight-home-topic-en {
  text-align: right;
  font-size: 24px;
  line-height: 30px;
  font-family: "siyuan-ExtraLight";
  font-weight: 200;
  color: #ffffff;
  opacity: 0.2;
}
.sight-home-topic-bar {
  width: 90px;
  height: 3px;
  background: #0075ff;
  border-radius: 1px;
  position: absolute;
  right: 0;
  top: 138px;
}
.sight-home-right {
  width: 1005px;
  height: 516px;
  background: #e5e5e5;
  opacity: 0.95;
  display: flex;
}
.sight-home-rl {
  width: 285px;
  margin-left: 150px;
  position: relative;
}
.sight-home-rr {
  width: 429px;
  height: 471px;
  background: url("../../assets/homec.png") no-repeat 0 0/100% 100%;
}
.sight-home-r11 {
  position: absolute;
  top: 77px;
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  line-height: 18px;
}
.sight-home-r12 {
  position: absolute;
  top: 164px;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  width: 284px;
  padding: 20px 0 30px;
}
.sight-home-child {
  display: flex;
  position: relative;
}
.sight-home-b {
  height: 36px;
  font-size: 36px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #0075ff;
}
.sight-home-c1 {
  height: 14px;
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  position: absolute;
  bottom: 0;
  left: 74px;
}
.sight-home-child1 {
  margin: 20px 0;
  position: relative;
}
.sight-home-c2 {
  font-size: 14px;
  line-height: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  position: absolute;
  top: 14px;
  left: 108px;
}
.sight-home-c3 {
  font-size: 14px;
  line-height: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  position: absolute;
  bottom: 0;
  left: 125px;
}
.sight-home-rl3 {
  position: absolute;
  top: 390px;
  display: flex;
}
.sight-home-c4 {
  font-size: 14px;
  line-height: 18px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  margin: 12px 0 0 14px;
}
.sight-number {
  width: 1280px;
  margin: 78px auto 0;
  display: flex;
}
.sight-number-left {
  margin: 0 70px 0 150px;
  width: 426px;
  height: 416px;
  background: url("../../assets/phone.png") no-repeat 0 0/100% 100%;
}
.sight-number-r1 {
  display: flex;
  margin-top: 70px;
}
.sight-number-cbar {
  width: 10px;
  height: 10px;
  background: #0075ff;
  border-radius: 50%;
  margin: 3px 10px 0 0;
}
.sight-number-main {
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  line-height: 18px;
}
.sight-number-r2 {
  display: flex;
  margin: 25px 0 45px 0;
}
.sight-home-bs {
  height: 48px;
  font-size: 48px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #0075ff;
}
.sight-number-r3 {
  display: flex;
  margin: 90px 0 0 0;
}
.sight-number-right {
  position: relative;
  width: 537px;
}
.sight-line {
  position: absolute;
  top: 203px;
  width: 537px;
  height: 1px;
  background: #333333;
}
.sight-number-topic {
  position: absolute;
  top: 62px;
  right: 0;
}
.sight-number-topic-cn {
  text-align: right;
  font-size: 28px;
  font-family: "siyuan-Normal";
  /* font-weight: bold; */
  color: #333333;
  line-height: 30px;
}
.sight-number-height {
  height: 10px;
}
.sight-number-topic-en {
  text-align: right;
  font-size: 30px;
  line-height: 32px;
  font-family: "siyuan-Regular";
  font-weight: 200;
  color: #000000;
  opacity: 0.2;
}
.sight-number-topic-bar {
  width: 90px;
  height: 3px;
  background: #0075ff;
  border-radius: 1px;
  position: absolute;
  right: 0;
  top: 140px;
}
.sight-official {
  width: 1280px;
  height: 402px;
  margin: 70px auto 0;
  display: flex;
}
.sight-official-left {
  width: 275px;
  height: 402px;
  background: #3b3b3b;
  opacity: 0.8;
  position: relative;
}
.sight-official-left-topic {
  position: absolute;
  top: 130px;
  right: 45px;
  position: relative;
}
.sight-official-right {
  width: 1005px;
  height: 402px;
  background: #e5e5e5;
  opacity: 0.95;
  display: flex;
  position: relative;
}
.sight-official-rr {
  width: 726px;
  height: 334px;
  margin-top: 20px;
  background: url("../../assets/official.png") no-repeat 0 0/100% 100%;
}
.sight-official-rl {
  width: 264px;
}
.sight-official-child1 {
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin-left: 45px;
  margin-top: 45px;
}
.sight-official-child {
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin-left: 45px;
}
.sight-official-child2 {
  width: 185px;
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 30px 0 30px 45px;
  padding: 20px 0;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}
.sight-official-n {
  line-height: 70px;
  font-size: 82px;
  font-family: "siyuan-Regular";
  font-weight: bold;
  color: #0075ff;
  opacity: 0.1;
  position: absolute;
  bottom: 0;
  left: 0;
}
.sight-serve {
  width: 999px;
  margin: 70px auto 130px;
}
.sight-serve-topic {
  display: flex;
  width: 999px;
  margin-bottom: 35px;
  justify-content: space-between;
}
.sight-serve-left {
  font-size: 28px;
  font-family: "siyuan-Normal";
  /* font-weight: bold; */
  color: #333333;
  line-height: 30px;
}
.sight-serve-en {
  font-size: 20px;
  line-height: 26px;
  font-family: "siyuan-Regular";
  font-weight: 200;
  color: #000000;
  opacity: 0.2;
  text-align: right;
}
.sight-serve-bar {
  width: 230px;
  height: 3px;
  background: #0075ff;
  border-radius: 1px;
}
.sight-serve-img {
  width: 999px;
  height: 183px;
  background: url("../../assets/serve.png") no-repeat 0 0/100% 100%;
}
.sight-serve-main {
  width: 999px;
  display: flex;
  justify-content: space-between;
}
.sight-serve-child {
  width: 333px;
}
.sight-child-topic {
  font-size: 20px;
  font-family: "siyuan-Regular";
  font-weight: bold;
  color: #333333;
  line-height: 18px;
  margin: 12px 0 30px;
  text-align: center;
}
.sight-child-topic1 {
  font-size: 20px;
  font-family: "siyuan-Regular";
  font-weight: bold;
  color: #333333;
  line-height: 18px;
  margin: 12px 0 14px;
  text-align: center;
}
.sight-child-mian {
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  text-align: center;
}
</style>
