<template>
  <div id="dynamic">
    <div id="dynamic-section" :class="{ 'dynamic-fix-nav': navBarFixed }">
      <div class="dynamic-section">
        <li class="dynamic-section-select">企业形象</li>
      </div>
    </div>
    <!-- 企业形象 -->
    <div class="content">
      <div style="padding-top: 24px; width: 1280px; margin: 0 auto">
        <div class="dynamic-media-content">
          <img
            class="mediaImg"
            v-lazy="item.filePath"
            v-for="(item, index) in businessPhotoList"
            :key="index"
            @click="showImg(index)"
          />
        </div>
      </div>
    </div>
    <!-- 企业形象 -->
    <div v-if="showImgFlag">
      <div class="image-pop" style="display: block">
        <div class="pop-bg" @click.stop="closeModule()"></div>
        <div class="pop-box">
          <img
            class="close"
            src="../../assets/close.png"
            @click.stop="closeModule()"
          />
          <div class="pic-box">
            <div
              class="pic-list active-pic"
              :style="{
                backgroundImage:
                  'url(' + businessPhotoList[showImgIndex].filePath + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }"
            ></div>
          </div>
        </div>
        <div
          class="pre-btn btn"
          v-show="showImgIndex > 0"
          @click.stop="changeImg('pre')"
        >
          <img src="../../assets/sli-left-icon.png" />
        </div>
        <div
          class="next-btn btn"
          v-show="showImgIndex < businessPhotoList.length - 1"
          @click.stop="changeImg('next')"
        >
          <img src="../../assets/sli-right-icon.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ipHeader, getBusinessPhotoList, visited } from "../../api/index";
export default {
  name: "businessImg",
  data() {
    return {
      topicCode: 1010,
      navBarFixed: false,
      showImgFlag: false, //显示图册预览模块
      showImgIndex: 0, //选择的图片

      businessPhotoList: [],
    };
  },
  created() {
    this.visit();
    this.show();
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },

  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
  },
  watch: {},
  methods: {
    visit() {
      this.visiting();
    },
    visiting() {
      visited(this.topicCode)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    /* 调用接口 */
    show() {
      /* 企业形象 */
      getBusinessPhotoList()
        .then((res) => {
          this.businessPhotoList = res.data.data;
          this.scrolltoTop();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /* 图片地址转换 */
    carouselImg(item) {
      let url = `${ipHeader}nsc/file${item}`;
      return url;
    },

    scrolltoTop() {
      this.$nextTick(() => {
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
        });
      });
    },

    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop =
        document.querySelector("#dynamic-section").offsetHeight +
        document.getElementsByClassName("nav-bg1")[0].offsetHeight -
        1;
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    /* 图集预览 */
    showImg(index) {
      this.showImgFlag = true;
      this.showImgIndex = index;
    },
    /* 关闭弹窗 */
    closeModule() {
      this.showVideoFlag = false;
      this.showImgFlag = false;
    },
    /* 切换图片 */
    changeImg(type) {
      switch (type) {
        case "pre":
          this.showImgIndex--;
          break;
        case "next":
          this.showImgIndex++;
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
#dynamic {
  width: 100%;
  margin: 0;
  padding: 0;
}
#dynamic-section {
  width: 100%;
  border-bottom: 1px solid #b8b8b8;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 2;
}
.dynamic-fix-nav {
  position: fixed;
  top: 0;
  z-index: 99;
}
.dynamic-section {
  display: flex;
  justify-content: flex-start;
  height: 54px;
  background-color: white;
  width: 1280px;
  margin: 0 auto;
}
#dynamic-section li {
  font: 14px/40px "";
  padding: 0px 3px;
  text-align: center;
  height: 52px;
  line-height: 52px;
  margin-left: 72px;
  font-size: 17px;
  font-weight: 400;
  color: #666666;
}
.dynamic-section-select {
  border-bottom: 3px solid #0075ff;
  border-radius: 2px;
}

.content {
  width: 100%;
  padding-bottom: 133px;
}
.title {
  color: #333333;
  font-size: 16px;
  padding: 6px 0px;
  height: 24px;
  line-height: 24px;
  border-bottom: 1px solid #b8b8b8;
}
.dynamic-media-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
  padding: 0 72px;
}
.dynamic-media-content::after {
  content: "";
  width: 333px;
}
.mediaImg {
  width: 333px;
  height: 200px;
  margin-top: 33px;
  cursor: pointer;
}
/* 图集模块 */
.image-pop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: none;
}
.image-pop .pop-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}
.image-pop .pop-box {
  width: 800px;
  height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background: #000;
  box-sizing: border-box;
}
.image-pop .pop-box .close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 13px;
  top: 13px;
  cursor: pointer;
  z-index: 9;
}
.image-pop .pop-box .pic-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.image-pop .pop-box .pic-box .pic-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.image-pop .pop-box .pic-box .pic-list.active-pic {
  display: block;
}
.image-pop .pop-box .pic-box .pic-list img {
  opacity: 0;
  width: 100%;
  height: 100%;
}
.image-pop .btn {
  width: 40px;
  height: 40px;
  background: #919797;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  cursor: pointer;
}
.image-pop .btn img {
  width: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.image-pop .pre-btn {
  left: 0;
}
.image-pop .next-btn {
  right: 0;
}
</style>
