var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dynamic"}},[_c('div',{class:{ 'dynamic-fix-nav': _vm.navBarFixed },attrs:{"id":"dynamic-section"}},[_vm._m(0)]),_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"width":"1280px","margin":"0 auto"}},_vm._l((_vm.activityList),function(item,index){return _c('div',{key:index,staticStyle:{"padding":"24px 72px 0px 72px"}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.newsTopic))])]),_c('div',{staticClass:"dynamic-media-content"},_vm._l((item.list),function(item1,index1){return _c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item1.filePath),expression:"item1.filePath"}],key:index1,staticClass:"mediaImg",on:{"click":function($event){return _vm.showImg(index, index1)}}})}),0)])}),0)]),(_vm.showImgFlag)?_c('div',[_c('div',{staticClass:"image-pop",staticStyle:{"display":"block"}},[_c('div',{staticClass:"pop-bg",on:{"click":function($event){$event.stopPropagation();return _vm.closeModule()}}}),_c('div',{staticClass:"pop-box"},[_c('img',{staticClass:"close",attrs:{"src":require("../../assets/close.png")},on:{"click":function($event){$event.stopPropagation();return _vm.closeModule()}}}),_c('div',{staticClass:"pic-box"},[_c('div',{staticClass:"pic-list active-pic",style:({
              backgroundImage:
                'url(' +
                _vm.activityList[_vm.showArrIndex].list[_vm.showImgIndex].filePath +
                ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
            })})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImgIndex > 0),expression:"showImgIndex > 0"}],staticClass:"pre-btn btn",on:{"click":function($event){$event.stopPropagation();return _vm.changeImg('pre')}}},[_c('img',{attrs:{"src":require("../../assets/sli-left-icon.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImgIndex < _vm.activityList[_vm.showArrIndex].list.length - 1),expression:"showImgIndex < activityList[showArrIndex].list.length - 1"}],staticClass:"next-btn btn",on:{"click":function($event){$event.stopPropagation();return _vm.changeImg('next')}}},[_c('img',{attrs:{"src":require("../../assets/sli-right-icon.png")}})])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dynamic-section"},[_c('li',{staticClass:"dynamic-section-select"},[_vm._v("活动图集")])])}]

export { render, staticRenderFns }