<template>
  <div class="impression">
    <div id="impression-section" :class="{ 'impression-fix-nav': navBarFixed }">
      <div class="impression-section">
        <li
          :class="{ 'impression-section-select': navBarSelected == 1 }"
          @click="jump(1)"
        >
          企业介绍
        </li>
        <li
          :class="{ 'impression-section-select': navBarSelected == 2 }"
          @click="jump(2)"
        >
          发展历程
        </li>
        <li
          :class="{ 'impression-section-select': navBarSelected == 3 }"
          @click="jump(3)"
        >
          团队管理
        </li>
        <li
          :class="{ 'impression-section-select': navBarSelected == 4 }"
          @click="jump(4)"
        >
          企业文化
        </li>
        <li
          :class="{ 'impression-section-select': navBarSelected == 5 }"
          @click="jump(5)"
        >
          联系我们
        </li>
      </div>
    </div>
    <div :class="{ 'fix-div': navBarFixed }"></div>
    <div id="impression-main">
      <div id="impression-main1"></div>
      <div class="impression-mains">
        <!-- <div id="team">
          <h3 id="h3">企业介绍</h3>
          <div class="h3-bar"></div>
        </div> -->
        <div class="intro">
          <p>
            江苏视界互联传媒有限公司,
            江苏省广电有线信息网络股份有限公司（股票代码600959）全资子公司。
          </p>
          <p>
            视界互联站在江苏有线大数据和网络技术实力的肩膀上，肩负着开拓集团新时代媒体融合转型之路的重任。顺应5G改革浪潮，作为“内容+技术+产品+服务”多轮驱动的创新型互联网生态媒体平台，视界互联推出“视界观”移动客户端和“新国货电商”两大拳头产品。其中，“视界观”移动客户端倡导科技未来、品质生活，以“5G+４K＋AI”为技术引擎，“内容+品牌+电商”为生态模式，打造权威的视频生产与传播端，助力行业品牌产业升级；“新国货电商”通过“优品100”项目与近百家龙头国货品牌厂家直连，构建出“乡村振兴”、“家乡好物”等一系列特色商城专区。
          </p>
          <p>
            目前，视界互联已经与各级地方政府、行业主管部门、特色产业园区、品牌龙头企业等在活动策划、媒介传播、品牌建设等多方面展开全方位合作。
          </p>
          <p>
            未来，视界互联将秉承“创造用户价值、实现商业增值”的服务宗旨，发展成为在新媒体、电商、移动互联网市场极具竞争力的品牌。
          </p>
        </div>
        <img src="../../assets/qiyejieshao.png" />
      </div>
    </div>
    <div id="impression-develop1"></div>
    <div id="impression-develop">
      <div class="impression-develop">
        <div class="impression-develop-year">
          <div class="impression-develop-year-content">
            <h3>发展历程</h3>
            <!-- <div class="quarters"> -->
            <!-- <div v-for="(item, index) in seasonList" :key="index">
                <div class="quarters-year" v-html="item.topic1"></div>
                <div
                  class="circle"
                  :class="{ 'circle-select': BarSelected == index + 1 }"
                  @click="active(index)"
                ></div>
              </div> -->
            <swiper class="quarters" :options="swiperOption2" ref="mySwiper1">
              <swiper-slide
                class="slide"
                :class="index + 1 == BarSelected ? 'slide-current' : ''"
                v-for="(item, index) in seasonList"
                :key="index"
              >
                <div
                  class="quarters-year"
                  v-html="item.topic1"
                  @click="active(index)"
                ></div>
                <div
                  class="circle"
                  :class="{ 'circle-select': BarSelected == index + 1 }"
                  @click="active(index)"
                ></div>
                <div
                  class="quarters-bar-new-left"
                  :class="{
                    'circle-select-quarters-bar': BarSelected == index + 1,
                  }"
                  v-show="index != 0"
                ></div>
                <div
                  class="quarters-bar-new-right"
                  :class="{
                    'circle-select-quarters-bar': BarSelected == index + 1,
                  }"
                  v-show="index != seasonList.length - 1"
                ></div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div id="impression-quarter">
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide
              class="slide"
              :class="index + 1 == BarSelected ? 'slide-current' : ''"
              v-for="(item, index) in seasonList"
              :key="index"
            >
              <div @click="active(index)">
                <h3>{{ item.topic }}</h3>
                <pre
                  v-for="(items, index) in item.newsCourseVoList"
                  :key="index"
                  @click.stop="activeTo(items.id, items.link)"
                  :class="items.link == 1 ? 'sbar' : ''"
                  >{{ items.newsTopic }}</pre
                >
              </div>
            </swiper-slide>
          </swiper>
          <!-- <li class="slide" v-for="(item, index) in arr2" :key="index">
            <div>
              <h3>{{ item.topic }}</h3>
              <pre>{{ item.content }}</pre>
            </div>
          </li> -->
        </div>
      </div>
    </div>
    <div id="team1"></div>
    <div id="team">
      <h3 id="h3">团队管理</h3>
      <div class="h3-bar"></div>
    </div>
    <div id="impression-tab">
      <div class="impression-tab">
        <div class="impression-tab-left">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            class="impression-tab-left-top"
            @mouseenter="mouse(index + 1)"
          >
            <span :class="{ 'mouse-span': tabBarSelected == index + 1 }">{{
              item.topic
            }}</span>
          </div>
        </div>
        <div class="impression-tab-right">
          <div
            class="impression-tab-right-a"
            v-for="(item, index) in tabs"
            :key="index"
          >
            <img
              :src="item.filePath"
              alt=""
              v-show="tabBarSelected === index + 1"
            />
            <p v-show="tabBarSelected === index + 1">{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="culture1"></div>
    <div id="culture">
      <div class="culture">
        <h3 class="culture-h3">企业文化</h3>
        <div class="culture-bar"></div>
        <div class="culture-child">
          <div class="culture-left">
            <h3 class="culture-h3">品牌及宗旨</h3>
            <p>
              秉承“创造用户价值、实现商业增值”的服务宗旨，构建创新型互联网生态媒体平台，打造“视界观”移动客户端成为视频、电商、服务类移动互联网市场极具竞争力的特色产品。
            </p>
          </div>
          <div class="culture-right">
            <h3 class="culture-h3">企业愿景</h3>
            <p>
              致力于打造长三角下沉市场渗透率最高的短视频媒体平台、特色的新国货品牌培育基地以及最有影响力的智库咨询决策机构。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="relation1"></div>
    <div id="relation">
      <div class="relation-child">
        <h3 class="relation-h3">联系我们</h3>
        <div class="relation-bar"></div>
        <div class="relation">
          <h3>商务合作咨询</h3>
          <span>·邮箱：wuxiaodan@jscnnet.com</span><br />
        </div>
        <div class="relation">
          <h3>视界观创作者入驻咨询</h3>
          <span>·微信：JSCN222</span><br />
        </div>
        <div class="relation">
          <h3>新国货商家入驻咨询</h3>
          <span>·电话：025-86731124</span><br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { trend, team, visited } from "../../api/index";
export default {
  name: "impression",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        autoplay: false,
        observer: true,
        observeParents: true,
        slidesPerView: 3,
        slideToClickedSlide: true,
      },
      swiperOption2: {
        loop: false,
        autoplay: false,
        observer: true,
        observeParents: true,
        slidesPerView: "auto",
        freeMode: true,
        slideToClickedSlide: true,
        disableScroll: true,
      },
      startScroll: false,
      navBarFixed: false,
      topicCode: 1002,
      navBarSelected: 1,
      BarSelected: 1,
      tabBarSelected: 1,
      quarters: [],
      arr1: [],
      arr2: [],
      tabs: [],
      selector: "",
      seasonList: [],
    };
  },
  filters: {
    /* 去除html标签 */
    clearHtmlCode(val) {
      return val.replace(/<[^>]+>/g, "");
    },
    /* 时间格式转换 */
    formatTime(val) {
      if (val) {
        return val.substring(5, 6) == 0
          ? val.substring(6, 7)
          : val.substring(5, 7);
      } else {
        return "";
      }
    },
    formatTimes(val) {
      if (val) {
        return val.substring(8, 9) == 0
          ? val.substring(9, 10)
          : val.substring(8, 10);
      } else {
        return "";
      }
    },
  },
  created() {
    this.visit();
    this.hos();
    this.addteam();
    this.$nextTick(() => {
      this.active(0);
      if (localStorage.getItem("toId")) {
        this.getlocal();
      } else {
        window.scrollTo({
          top: document.getElementsByClassName("nav-bg1")[0].offsetHeight,
        });
      }
    });
  },

  methods: {
    visit() {
      this.visiting();
    },
    visiting() {
      visited(this.topicCode)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId);
      //如果对应id存在，就跳转
      if (selectId && toElement) {
        //console.log(toElement,'toElement')
        toElement.scrollIntoView(true);
        localStorage.setItem("toId", "");
      }
    },
    /* 跳转锚点 */
    gotoSelectId() {
      this.$nextTick(() => {
        if (localStorage.getItem("toId")) {
          this.getlocal();
        }
      });
    },
    jump(index) {
      let that = this;
      //if (this.navBarSelected != index) {
      this.navBarSelected = index;
      if (index == 1) {
        this.selector = "#impression-main1";
      } else if (index == 2) {
        this.selector = "#impression-develop1";
      } else if (index == 3) {
        this.selector = "#team1";
      } else if (index == 4) {
        this.selector = "#culture1";
      } else if (index == 5) {
        this.selector = "#relation1";
      }

      let total = this.$el.querySelector(this.selector).offsetTop;
      let distance = document.documentElement.scrollTop;

      this.startScroll = true;
      if (total > distance) {
        window.scrollTo({
          top: total,
          behavior: "smooth",
        });
        setTimeout(() => {
          that.startScroll = false;
        }, 600);
      } else {
        window.scrollTo({
          top: total,
          behavior: "smooth",
        });
        setTimeout(() => {
          that.startScroll = false;
        }, 600);
      }
      //} else {
      //return;
      //}
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop =
        document.querySelector("#impression-section").offsetHeight +
        document.getElementsByClassName("nav-bg1")[0].offsetHeight -
        1;
      //console.log(offsetTop);
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }

      /* 吸顶栏定位 */
      if (!this.startScroll) {
        if (
          document.querySelector("#impression-main1").offsetTop - 55 <
          document.documentElement.scrollTop
        ) {
          this.navBarSelected = 1;
        }
        if (
          document.querySelector("#impression-develop1").offsetTop - 55 <
          document.documentElement.scrollTop
        ) {
          this.navBarSelected = 2;
        }
        if (
          document.querySelector("#team1").offsetTop - 55 <
          document.documentElement.scrollTop
        ) {
          this.navBarSelected = 3;
        }
        if (
          document.querySelector("#culture1").offsetTop - 55 <
          document.documentElement.scrollTop
        ) {
          this.navBarSelected = 4;
        }
        if (
          document.querySelector("#relation1").offsetTop - 55 <
          document.documentElement.scrollTop
        ) {
          this.navBarSelected = 5;
        }
      }
    },
    addteam() {
      team()
        .then((res) => {
          //console.log(res);
          this.tabs = res.data.data;
          // this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hos() {
      trend()
        .then((res) => {
          console.log(res);
          this.quarters = res.data.data;
          this.seasonList = JSON.parse(JSON.stringify(res.data.data));
          this.seasonList.forEach((item) => {
            item.topic1 =
              item.topic.substring(0, 4) +
              "<br>" +
              item.topic.substring(4, item.topic.length);
          });
          // console.log(this.seasonList);
          // this.gotoSelectId();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    active(index) {
      this.$refs.mySwiper.swiper.slideTo(index, 0, true);
      this.$refs.mySwiper1.swiper.slideTo(index, 0, true);
      index++;
      this.BarSelected = index;
    },
    mouse(index) {
      this.tabBarSelected = index;
    },
    activeTo(id, link) {
      if (link == 0) {
        return "";
      } else if (link == 1) {
        this.$router.push({ path: "/developnews", query: { id: id } });
      }
    },
  },
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
    this.active();
  },

  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.watchScroll);
    // localStorage.setItem("toId", "");
  },
};
</script>
<style scoped>
#impression-section {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #b8b8b8;
}
#impression-section .impression-section {
  display: flex;
  justify-content: flex-start;
  height: 55px;
  width: 1280px;
  margin: 0 auto;
}
#impression-section .impression-section li {
  font: 16px/53px "siyuan-Regular";
  height: 53px;
  text-align: center;
  margin: 0 44px;
  color: #666666;
  cursor: pointer;
}
#impression-section .impression-section li:nth-of-type(1) {
  margin-left: 74px;
}
#impression-section .impression-section .impression-section-select {
  border-bottom: 3px solid #0075ff;
  border-radius: 2px;
}
.impression-fix-nav {
  position: fixed;
  top: 0;
  z-index: 99;
}
.fix-div {
  height: 56px;
}
#impression-main1 {
  width: 100%;
  height: 50px;
}
#impression-main {
  width: 1280px;
  margin: 0 auto;
}
#impression-main .impression-mains {
  /* padding: 60px 179px 0 190px; */
  /* padding: 60px 40px 0px 40px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 97px 97px 0px 97px;
}
#impression-main .impression-mains p {
  font-size: 16px;
  font-family: siyuan-Regular;
  font-weight: 400;
  color: #333333;
  text-indent: 2em;
  margin-bottom: 23px;
  /* padding: 0px 100px; */
}
#impression-main .impression-mains .intro {
  width: 688px;
}
#impression-main .impression-mains img {
  width: 336px;
  height: 202px;
}
#impression-develop1 {
  width: 100%;
  height: 50px;
}
#impression-develop {
  width: 100%;
}
#impression-develop .impression-develop {
  width: 100%;
  margin: 0 auto;
}
#impression-develop .impression-develop .impression-develop-year {
  background-image: url("../../assets/impression_history.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(440px * 2 / 3);
  position: relative;
  width: 100%;
}
#impression-develop .impression-develop .impression-develop-year::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.impression-develop-year-content {
  width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#impression-develop .impression-develop .impression-develop-year h3 {
  font: 28px / 100px "siyuan-Normal";
  margin: calc(63px * 2 / 3) 0 0 0;
  color: #fff;
  text-align: center;
}
#impression-develop .impression-develop .impression-develop-year .quarters {
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  /* position: absolute; */
  /* left: calc(310px * 2 / 3); */
  z-index: 9;
}
#impression-develop
  .impression-develop
  .impression-develop-year
  .quarters
  >>> .swiper-wrapper {
  /* justify-content: center !important; */
  width: 100%;
  margin: 0 auto;
}
#impression-develop
  .impression-develop
  .impression-develop-year
  .quarters
  .swiper-slide {
  width: 213px !important;
}
#impression-develop
  .impression-develop
  .impression-develop-year
  .quarters
  .quarters-year {
  width: 80px;
  font-size: 20px;
  font-family: "siyuan-Medium";
  color: #fff;
  text-align: center;
  white-space: nowrap;
  margin: 0 auto;
  cursor: pointer;
}
#impression-develop
  .impression-develop
  .impression-develop-year
  .quarters
  .circle {
  margin: 39px auto 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
#impression-develop
  .impression-develop
  .impression-develop-year
  .quarters
  .circle-select {
  margin: 33px auto 0;
  width: calc(40px * 2 / 3);
  height: calc(40px * 2 / 3);
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
#impression-develop .impression-develop .impression-develop-year .quarters-bar {
  width: 600px;
  margin: 0 auto;
  height: calc(5px * 2 / 3);
  background-color: #fff;
  position: absolute;
  top: calc(372px * 2 / 3);
  left: calc(366px * 2 / 3);
  z-index: 2;
}
.quarters-bar-new-left {
  height: 3px;
  width: 100px;
  background-color: #fff;
  position: absolute;
  bottom: 9px;
}
.quarters-bar-new-right {
  height: 3px;
  width: 120px;
  background-color: #fff;
  position: absolute;
  bottom: 9px;
  left: 110px;
}
.circle-select-quarters-bar {
  bottom: 10px;
}
.sbar {
  cursor: pointer !important;
  /* color: #0075ff !important; */
}
#impression-develop .impression-develop #impression-quarter {
  width: 1280px;
  margin: 0 auto;
  min-height: 585px;
  display: flex;
}
#impression-develop .impression-develop #impression-quarter .slide {
  display: block;
  width: calc(640px * 2 / 3);
}
#impression-develop .impression-develop #impression-quarter .slide-current {
  width: calc(800px * 2 / 3);
}
#impression-develop .impression-develop #impression-quarter .slide div {
  width: 320px;
  /* height: calc(250px * 2 / 3); */
  margin: 0 auto;
  margin-top: calc(150px * 2 / 3);

  transition: all 0.3s;
  -moz-transition: all 0.3s; /* Firefox 4 */
  -webkit-transition: all 0.3s; /* Safari and Chrome */
  -o-transition: all 0.3s; /* Opera */
}
#impression-develop .impression-develop #impression-quarter .slide div h3 {
  font: 24px / calc(70px * 2 / 3) "siyuan-Normal";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 30px;
  /* font-style: italic; */
}
#impression-develop .impression-develop #impression-quarter .slide div pre {
  font: 16px / 20px "siyuan-Regular";
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  /* word-wrap: break-word;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; */
  /* font-style: italic; */
  margin-bottom: 12px;
}
#impression-develop .impression-develop #impression-quarter .slide-current div {
  width: 320px;
  /* height: calc(320px * 2 / 3); */
  margin: calc(74px * 2 / 3) auto;
}
#impression-develop
  .impression-develop
  #impression-quarter
  .slide-current
  div
  h3 {
  font: 24px / calc(70px * 2 / 3) "siyuan-Normal";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 30px;
  /* font-style: italic; */
}
#impression-develop
  .impression-develop
  #impression-quarter
  .slide-current
  div
  pre {
  font: 16px / calc(35px * 2 / 3) "siyuan-Regular";
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  /* word-wrap: break-word;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; */
  /* font-style: italic; */
}
#team1 {
  width: 100%;
  height: 40px;
}
#team {
  width: 1280px;
  margin: 0 auto;
  height: 120px;
}
#team #h3 {
  font: 28px / 80px "siyuan-Normal";
  text-align: center;
  margin: 0 0 calc(40px * 2 / 3) 0;
}
#team .h3-bar {
  width: calc(170px * 2 / 3);
  height: 4px;
  background: #0075ff;
  margin-left: calc(900px * 2 / 3);
  margin-top: calc(-70px * 2 / 3);
  border-radius: 2px;
}
#impression-tab {
  width: 100%;
}
#impression-tab .impression-tab {
  width: 1280px;
  min-height: 620px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background-color: rgb(59, 59, 59);
}
#impression-tab .impression-tab-left {
  width: 400px;
}
#impression-tab .impression-tab-left .impression-tab-left-top {
  width: 400px;
  height: 120px;
  font: 28px / 120px "siyuan-Normal";
  text-align: center;
  color: #666666;
}
#impression-tab .impression-tab-left .impression-tab-left-top .mouse-span {
  padding: 0 calc(20px * 2 / 3);
  margin-left: calc(-20px * 2 / 3);
  border-left: 2px solid #0075ff;
  color: #fff;
}

#impression-tab .impression-tab .impression-tab-right {
  width: calc(1320px * 2 / 3);
  background-color: #ccc;
}
#impression-tab .impression-tab .impression-tab-right .impression-tab-right-a {
  background-color: #ccc;
  width: 700px;
  margin: 0 auto;
}
#impression-tab
  .impression-tab
  .impression-tab-right
  .impression-tab-right-a
  img {
  width: 700px;
  margin: 50px auto 25px;
}
#impression-tab
  .impression-tab
  .impression-tab-right
  .impression-tab-right-a
  p {
  width: 700px;
  margin: 0 auto 30px;
  font-size: 16px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  text-indent: 2rem;
}
#culture1 {
  width: 100%;
  height: 40px;
}
#culture {
  width: 100%;
}
#culture .culture {
  width: 1280px;
  background-image: url("../../assets/impression_culture.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(870px * 2 / 3);
  margin: calc(110px * 2 / 3) auto;
}
#culture .culture .culture-child {
  display: flex;
  justify-content: space-between;
  height: calc(570px * 2 / 3);
  margin-top: calc(50px * 2 / 3);
}
#culture .culture-bar {
  width: calc(170px * 2 / 3);
  height: 4px;
  background: #0075ff;
  margin-left: calc(900px * 2 / 3);
  margin-top: calc(-55px * 2 / 3);
  border-radius: 2px;
}
#culture .culture-h3 {
  text-align: center;
  font: 28px / 120px "siyuan-Normal";
}
#culture .culture-child .culture-h3 {
  text-align: center;
  font: 24px / 66px "siyuan-Medium";
}
#culture .culture .culture-child .culture-left {
  width: 50%;
  border-right: 1px solid #000;
}
#culture .culture .culture-child .culture-left p {
  margin: calc(60px * 2 / 3) calc(100px * 2 / 3);
  font: 20px / 40px "siyuan-Normal";
}
#culture .culture .culture-child .culture-right {
  width: 50%;
}
#culture .culture .culture-child .culture-right p {
  margin: calc(60px * 2 / 3) calc(100px * 2 / 3);
  font: 20px / 40px "siyuan-Normal";
}
#relation1 {
  width: 100%;
  height: 40px;
}
#relation {
  width: 100%;
  height: 850px;
}
#relation .relation-child {
  width: 1280px;
  margin: 0 auto;
}
#relation .relation-child .relation-h3 {
  font: 28px / 100px "siyuan-Normal";
  text-align: center;
  margin: calc(20px * 2 / 3) 0;
}
#relation .relation-child .relation-bar {
  width: calc(170px * 2 / 3);
  height: 4px;
  background: #0075ff;
  margin-left: calc(900px * 2 / 3);
  margin-top: calc(-55px * 2 / 3);
  border-radius: 2px;
}
#relation .relation-child .relation {
  margin: calc(40px * 2 / 3) 0 80px 140px;
}
#relation .relation-child .relation h3 {
  font: bold calc(40px * 2 / 3) / 80px "siyuan-Regular";
}
#relation .relation-child .relation span {
  font: 20px / calc(100px * 2 / 3) "siyuan-Regular";
}
</style>
